import { convertZeroToEmpty } from '../../utils'

import {
  motoPhotoID,
  motoPhotoURL,
} from '../../../constants/images/sizesAndQuality'

export const listingEditAPITransformer = (data) => {
  let listingEditFormatted = {
    user_id: data.owner.pretty_id,
    bike_year: data.year.toString(),
    bike_make: data.make,
    bike_model: data.model,
    license_plate_issuing_country: data.license_plate_country_abbreviation,
    license_plate_issuing_state: data.license_plate_state_abbreviation,
    license_plate_number: data.license_plate,
    bike_nickname: data.nickname,
    bike_description: data.description,
    rental_notes: data.rental_notes,
    bike_images: [],
    bike_daily_rate_cents: convertZeroToEmpty(data.daily_rental_price_cents),
    bike_daily_rate_currency: data.daily_rental_price_currency,
    for_sale: data.for_sale,
    contact_for_sale: data.contact_for_sale,
    high_value: data.high_value,
    sale_price: data.sale_price,
    bike_timezone: data.timezone,
    bike_address_line_1: data.location.address_street_1,
    bike_address_line_2: data.location.address_street_2,
    bike_address_city: data.location.city,
    bike_address_state_province_region: data.location.region_abbreviation,
    bike_address_country: data.location.country_abbreviation,
    bike_address_postal_code: data.location.postal_code,
    engine: convertZeroToEmpty(data.displacement_cc),
    seat_height: convertZeroToEmpty(data.seat_height_in),
    weight: convertZeroToEmpty(data.weight_lbs),
    fuel_tank_capacity: convertZeroToEmpty(data.fuel_capacity_gal),
    tire_treads: data.tire_treads,
    last_serviced_date: data.last_serviced_date,
    miles_to_refuel: convertZeroToEmpty(data.fuel_capacity_mi),
    odometer: convertZeroToEmpty(data.odometer_miles),
    motorcycle_state: data.state === 'pending' ? 'incomplete' : data.state,
    motorcycle_pretty_id: data.pretty_id,
    motorcycle_working_hours: data.motorcycle_working_hours,
    category: data.category,
    model_listing_inventory_id: '',
    helmets: [
      { size: 'XS', type: 'Full Face' },
      { size: 'MD', type: 'Flip Up' },
    ], //data.gear.helmets
  }

  for (let i = 0; i < data.images.length; i++) {
    listingEditFormatted.bike_images.push({
      secure_url: motoPhotoURL(data.images[i].url),
      cloudinary_public_id: motoPhotoID(data.images[i].cloudinary_public_id),
    })
  }
  return listingEditFormatted
}
