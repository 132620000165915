export default [
  "pending",
  "pending_rider_approval",
  "pending_system_approval",
  "pending_owner_approval",
  "pending_admin_approval",
  "pending_payment_system_approval",
  "pending_rider_data",
  "pending_admin_interaction",
  "needs_attention",
  "declined",
]