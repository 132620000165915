import { referralsInviteTransformer } from '../transformers/referralsInviteTransformer.js'
export const REFERRALS_CALLS = {
  'referrals-send': {
    use_mock: false,
    mock_endpoint: 'referrals/send',
    api_type: 'POST',
    requires_auth: true,
  },
  'referrals-landing-page': {
    use_mock: false,
    mock_endpoint: 'referrals/invite/jamesw1986',
    api_type: 'POST',
    requires_auth: false,
    transFormData(data) {
      return referralsInviteTransformer(data)
    },
  },
  'get-referral-info': {
    use_mock: false,
    mock_endpoint: 'referrals/info',
    api_type: 'GET',
    requires_auth: false,
  }
}
