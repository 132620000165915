import { calculateAverageRateScore } from '../../utils'
import {
  userPhotoID,
  userPhotoURL,
} from '../../../constants/images/sizesAndQuality'

export const reviewsFromRidersAPITransformer = (data) => {
  let reviewCleanData = []
  //build reviews from guests
  for (let i = 0; i < data.length; i++) {
    reviewCleanData.push({
      has_bike_review: data[i].review.of_motorcycle_submitted,
      has_owner_review: data[i].review.of_owner_submitted,
      guest_review_id: null,
      guest_review_name:
        data[i].rider.first_name + ' ' + data[i].rider.last_initial,
      guest_id: data[i].rider.pretty_id,
      guest_review_date:
        data[i].review.of_motorcycle.created_at !== null
          ? data[i].review.of_motorcycle.created_at
          : data[i].review.of_owner.created_at,
      guest_review_avatar: userPhotoURL(data[i].rider.avatar.url),
      guest_review_avatar_cloudinary_public_id: userPhotoID(
        data[i].rider.avatar.cloudinary_public_id
      ),
      guest_review_rating: calculateAverageRateScore([
        data[i].review.of_motorcycle.score,
        data[i].review.of_owner.score,
      ]),
      guest_bike_review_text: data[i].review.of_motorcycle.description,
      guest_owner_review_text: data[i].review.of_owner.description,
      guest_review_bike_id: data[i].motorcycle.pretty_id,
      guest_review_bike_title:
        data[i].motorcycle.year +
        ' ' +
        data[i].motorcycle.make +
        ' ' +
        data[i].motorcycle.model,
      guest_review_images: data[i].review.of_motorcycle.images,
      guest_review_make: data[i].motorcycle.make,
      guest_review_model: data[i].motorcycle.model,
      guest_review_bike_year: data[i].motorcycle.year,
      guest_location: data[i].rider.location,
      motorcycle_location: data[i].motorcycle.location,
      motorcycle_active: data[i].motorcycle.state === 'active' ? true : false,
      rider_active: data[i].rider.state === 'active' ? true : false,
      owner_rating: data[i].review.of_owner.score,
      motorcycle_rating: data[i].review.of_motorcycle.score,
      //"location" : data[i].motorcycle.location,
    })
  }

  return reviewCleanData
}
