import { lazyWithRetry } from 'components/lazyUtil.js'

const MotorcycleEventDetails = lazyWithRetry(() =>
  import('components/motorcycleEvents/details')
)
const ListMotorcycleEvents = lazyWithRetry(() =>
  import('components/motorcycleEvents')
)
const CreateMotorcycleEvent = lazyWithRetry(() =>
  import('components/motorcycleEvents/create')
)
const SearchMotorcycleEvents = lazyWithRetry(() =>
  import('components/motorcycleEvents/search')
)

const routes = [
  {
    path: '/events',
    component: ListMotorcycleEvents,
    is_private: false,
  },
  {
    path: '/events/search',
    component: SearchMotorcycleEvents,
    is_private: false,
  },
  {
    path: '/events/create',
    component: CreateMotorcycleEvent,
    is_private: true,
  },
  {
    path: '/events/:prettyId/details',
    component: MotorcycleEventDetails,
    is_private: false,
  },
]

export default routes
