export const MASTER_QUALITY = 'jpegmini'

export const MOTO_PHOTO_DEFAULT_URL = process.env.REACT_APP_DEFAULT_MOTO_PHOTO_URL
export const USER_AVATAR_DEFAULT_URL = process.env.REACT_APP_DEFAULT_USER_AVATAR_URL

export const MOTO_PHOTO_DEFAULT_ID = process.env.REACT_APP_DEFAULT_MOTO_PHOTO_ID
export const USER_AVATAR_DEFAULT_ID = process.env.REACT_APP_DEFAULT_USER_AVATAR_ID

export const userPhotoURL = (userPhoto) => {
    if(userPhoto === null || userPhoto === "null" || userPhoto === undefined || userPhoto === "undefined") {
        return USER_AVATAR_DEFAULT_URL
    }
    else {
        return userPhoto;            
    }
}

export const motoPhotoURL = (motoPhoto) => {
    if(motoPhoto == null) {
      return MOTO_PHOTO_DEFAULT_URL
    }
    else {
      return motoPhoto;            
    }
}


export const userPhotoID = (userPhotoID) => {
  if(userPhotoID === null || userPhotoID === "null" || userPhotoID === undefined || userPhotoID === "undefined") {
      return USER_AVATAR_DEFAULT_ID
  }
  else {
      return userPhotoID;            
  }
}

export const motoPhotoID = (motoPhotoID) => {
  if(motoPhotoID == null) {
    return MOTO_PHOTO_DEFAULT_ID
  }
  else {
    return motoPhotoID;            
  }
}

//MOTORCYCLE SEARCH DISPLAY SIZE
export const MOTO_SEARCH_DISPLAY_HEIGHT = 250
export const MOTO_SEARCH_DISPLAY_WIDTH = 425
export const MOTO_SEARCH_DISPLAY_GRAVITY = 'auto'
export const MOTO_SEARCH_DISPLAY_CROP = 'fill'
export const MOTO_SEARCH_DISPLAY_FORMAT = 'jpg'
export const MOTO_SEARCH_DISPLAY_QUALITY = 'auto'
export const MOTO_SEARCH_DISPLAY_FETCH_FORMAT = 'auto'

export const SEARCH_IMAGE_PARAMS_PROGRESSIVE_SMALL = {
  gravity: MOTO_SEARCH_DISPLAY_GRAVITY,
  height: 2,
  width: 4,
  crop: MOTO_SEARCH_DISPLAY_CROP,
  format: MOTO_SEARCH_DISPLAY_FORMAT,
  quality: MOTO_SEARCH_DISPLAY_QUALITY,
  fetch_format: MOTO_SEARCH_DISPLAY_FETCH_FORMAT
}

export const SEARCH_IMAGE_PARAMS = {
  gravity: MOTO_SEARCH_DISPLAY_GRAVITY,
  height: MOTO_SEARCH_DISPLAY_HEIGHT,
  width: MOTO_SEARCH_DISPLAY_WIDTH,
  crop: MOTO_SEARCH_DISPLAY_CROP,
  format: MOTO_SEARCH_DISPLAY_FORMAT,
  quality : MOTO_SEARCH_DISPLAY_QUALITY,
  fetch_format: MOTO_SEARCH_DISPLAY_FETCH_FORMAT
}

export const ADV_SEARCH_IMAGE_PARAMS = {
  height: MOTO_SEARCH_DISPLAY_HEIGHT,
  width: MOTO_SEARCH_DISPLAY_WIDTH,
}

//USER AVATAR TINY SIZE
export const USER_TINY_GRAVITY = "center"
export const USER_TINY_HEIGHT = 25
export const USER_TINY_WIDTH = 25 
export const USER_TINY_CROP = "fill"
export const USER_TINY_FORMAT = "jpg"

export const USER_TINY_PARAMS = {
  gravity : USER_TINY_GRAVITY,
  height : USER_TINY_HEIGHT,
  width : USER_TINY_WIDTH,
  crop : USER_TINY_CROP,
  format : USER_TINY_FORMAT
}


//USER AVATAR SMALL SIZE
export const USER_SMALL_GRAVITY = "center"
export const USER_SMALL_HEIGHT = 50
export const USER_SMALL_WIDTH = 50
export const USER_SMALL_CROP = "fill"
export const USER_SMALL_FORMAT = "jpg"

export const USER_SMALL_PARAMS = {
  gravity : USER_SMALL_GRAVITY,
  height : USER_SMALL_HEIGHT,
  width : USER_SMALL_WIDTH,
  crop : USER_SMALL_CROP,
  format : USER_SMALL_FORMAT
}


//USER AVATAR MEDIUM SIZE
export const USER_MEDIUM_GRAVITY = "center"
export const USER_MEDIUM_HEIGHT = 100
export const USER_MEDIUM_WIDTH = 100 
export const USER_MEDIUM_CROP = "fill"
export const USER_MEDIUM_FORMAT = "jpg"

export const USER_MEDIUM_PARAMS = {
  gravity : USER_MEDIUM_GRAVITY,
  height : USER_MEDIUM_HEIGHT,
  width : USER_MEDIUM_WIDTH,
  crop : USER_MEDIUM_CROP,
  format : USER_MEDIUM_FORMAT
}

//USER AVATAR LARGE SIZE
export const USER_LARGE_GRAVITY = "center"
export const USER_LARGE_HEIGHT = 150
export const USER_LARGE_WIDTH = 150
export const USER_LARGE_CROP = "fill"
export const USER_LARGE_FORMAT = "jpg"

export const USER_LARGE_PARAMS = {
  gravity : USER_LARGE_GRAVITY,
  height : USER_LARGE_HEIGHT,
  width : USER_LARGE_WIDTH,
  crop : USER_LARGE_CROP,
  format : USER_LARGE_FORMAT
}

//BIKE RATING REVIEW IMAGE SIZE
export const BIKE_RATING_REVIEW_IMAGE_HEIGHT = 600
export const BIKE_RATING_REVIEW_IMAGE_WIDTH = 900
export const BIKE_RATING_REVIEW_IMAGE_GRAVITY = 'auto'
export const BIKE_RATING_REVIEW_IMAGE_CROP = 'fill'
export const BIKE_RATING_REVIEW_IMAGE_FORMAT = 'jpg'
export const BIKE_RATING_REVIEW_IMAGE_QUALITY = 'auto'
export const BIKE_RATING_REVIEW_IMAGE_FETCH_FORMAT = 'auto'

export const RATING_REVIEW_IMAGES_PARAMS = {
  gravity : BIKE_RATING_REVIEW_IMAGE_GRAVITY,
  height : BIKE_RATING_REVIEW_IMAGE_HEIGHT,
  width : BIKE_RATING_REVIEW_IMAGE_WIDTH,
  crop : BIKE_RATING_REVIEW_IMAGE_CROP,
  format : BIKE_RATING_REVIEW_IMAGE_FORMAT,
  quality: BIKE_RATING_REVIEW_IMAGE_QUALITY,
  fetch_format: BIKE_RATING_REVIEW_IMAGE_FETCH_FORMAT,
  sign_url: false,
  type: 'fetch'
}