import { lazyWithRetry } from 'components/lazyUtil.js'

const BikeListing = lazyWithRetry(() => import('../../bikes/Bikelisting'))
const TwentyPointInspection = lazyWithRetry(() =>
  import('./../../bikes/inspection/twentyPointInspection.js')
)
const Search = lazyWithRetry(() => import('../../search/search.js'))

const routes = [
  {
    path: '/bikes/:bikeCountry/:bikeRegion/:bikeCity/:bikeName/:bikeID',
    component: BikeListing,
    is_private: false,
  },
  {
    path: '/search',
    component: Search,
    is_private: false,
  },
  {
    path: '/20-point-inspection',
    component: TwentyPointInspection,
    is_private: false,
  },
]

export default routes
