export const buildBikeURL = (bikeInfo) => {
  const { country, region, city, year, make, model, bikeID } = bikeInfo

  let regExpSlashes = /\\|\//g
  let regExpWhiteSpace = /\s+/g

  let rXSS = regExpSlashes
  let rXWS = regExpWhiteSpace

  let repWhiteSpace = (value) => value.replace(rXWS, '-').toLowerCase() //Remove Any White Space
  let repWhiteSpaceSlashes = (value) =>
    value.replace(rXWS, '-').replace(rXSS, '') //Remove White Space and Slashes

  let urlSegments = {
    country: repWhiteSpace(country),
    region: repWhiteSpace(region),
    city: repWhiteSpaceSlashes(city),
    year: repWhiteSpaceSlashes(year),
    make: repWhiteSpaceSlashes(make),
    model: repWhiteSpaceSlashes(model),
    uuid: bikeID,
  }

  let seg = urlSegments

  let url = `/bikes/${seg.country}/${seg.region}/${seg.city}/${seg.year}-${seg.make}-${seg.model}/${seg.uuid}`

  return encodeURI(url.toLowerCase())
}
