import { lazyWithRetry } from 'components/lazyUtil.js'

const ConversationsWrapper = lazyWithRetry(() => import('../../conversations'))

const routes = [
  {
    path: '/conversations',
    component: ConversationsWrapper,
    is_private: true,
  },
  {
    path: '/conversations/:conversationId',
    component: ConversationsWrapper,
    is_private: true,
  },
]

export default routes
