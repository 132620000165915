export const AUTH_ACCOUNT_CALLS = {
  'create-account': {
    use_mock: false,
    mock_endpoint: 'users',
    api_type: 'POST',
    requires_auth: false,
  },
  login: {
    use_mock: false,
    mock_endpoint: 'users/sessions',
    api_type: 'POST',
    requires_auth: false,
  },
  'masquerade': {
    use_mock: false,
    mock_endpoint: 'users/sessions/masquerade',
    api_type: 'POST',
    requires_auth: true,
  },
  refresh: {
    use_mock: false,
    mock_endpoint: 'users/sessions/refresh',
    api_type: 'POST',
    requires_auth: true,
  },

  'logged-in-check': {
    use_mock: false,
    mock_endpoint: 'whoami',
    api_type: 'GET',
    requires_auth: false,
  },

  logout: {
    use_mock: false,
    mock_endpoint: 'users/sessions',
    api_type: 'DELETE',
    requires_auth: false,
  },
}

export const AUTH_PASSWORD_CALLS = {
  'request-password-reset': {
    use_mock: false,
    mock_endpoint: 'users/request_password_reset',
    api_type: 'POST',
    requires_auth: false,
  },

  'validate-password-reset': {
    use_mock: false,
    mock_endpoint: '',
    api_type: 'POST',
    requires_auth: false,
  },

  'reset-password-w-token': {
    use_mock: false,
    mock_endpoint: 'users/reset_password',
    api_type: 'PUT',
    requires_auth: false,
  },

  'change-password-logged-in': {
    use_mock: false,
    mock_endpoint: 'users/change_password',
    api_type: 'PUT',
    requires_auth: true,
  },
}

export const AUTH_EMAIL_CALLS = {
  'resend-verf-email': {
    use_mock: false,
    mock_endpoint: 'users/validations/resend_email_token',
    api_type: 'PUT',
    requires_auth: true,
  },

  'validate-email-token': {
    use_mock: false,
    mock_endpoint: 'users/validations/validate_email_token',
    api_type: 'POST',
    requires_auth: false,
  },
}

export const AUTH_PHONE_CALLS = {
  'send-phone-verf-sms': {
    use_mock: false,
    mock_endpoint: 'users/validations/send_phone_token',
    api_type: 'PUT',
    requires_auth: true,
  },
  'validate-phone-token': {
    use_mock: false,
    mock_endpoint: 'users/validations/validate_phone_token',
    api_type: 'POST',
    requires_auth: true,
  },
}

export const AUTHENTICATION_CALLS = {
  ...AUTH_ACCOUNT_CALLS,
  ...AUTH_PASSWORD_CALLS,
  ...AUTH_PHONE_CALLS,
  ...AUTH_EMAIL_CALLS,
}
