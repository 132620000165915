import moment from 'moment-timezone/builds/moment-timezone-with-data';

const yearBirthOptions = () => {
    let yearBirthOptions = []
    for(let i = 0; i < 100; i++) {
        yearBirthOptions.push({
            key: moment().subtract(i, 'years').format('YYYY').toString(),
            value: moment().subtract(i, 'years').format('YYYY').toString(),
            text: moment().subtract(i, 'years').format('YYYY').toString()
        })  
    }
    return yearBirthOptions
}

export const YEAR_BIRTH_OPTIONS = yearBirthOptions()