import 'react-app-polyfill/ie11'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App.js'
import TagManager from 'react-gtm-module'
import mixpanel from 'mixpanel-browser'
import { MIXPANEL_ID } from './constants'
import { campaignParams } from './components/elements/tracking/campaignTracking.js'

/* We intialize our site wide tracking services here. */

//Mixpanel
mixpanel.init(MIXPANEL_ID)
campaignParams(mixpanel)

//Tag Manager
TagManager.initialize({
  gtmId: 'GTM-MR24H93', //move value to constants
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW_ENV,
})

ReactDOM.render(<App />, document.getElementById('root'))
