import * as Cookies from 'js-cookie'

export const setFeatureFlag = (queryParams) => {
  const features = getFeatureFlags()
  if (queryParams.enable_feature) {
    features[queryParams.enable_feature] = true
  }

  if (queryParams.disable_feature) {
    features[queryParams.disable_feature] = false
  }

  localStorage.setItem('features', JSON.stringify(features))
}

export const isConversationFeatureEnabled = () => {
  return (
    (Cookies.get('userIsPartner') !== 'true') ||
    (Cookies.get('userIsPartner') == 'true' && Cookies.get('userPartnerAllowMessaging') == 'true')
  )
}

export const isConversationEnabled = () => {
  return Cookies.get('userIsPartner') == 'true' && Cookies.get('userPartnerAllowMessaging') == 'true'
}

export const driversLicenseAfterBooking = () => {
  return process.env.REACT_APP_DRIVERS_LICENSE_AFTER_BOOKING === 'true'
}

export const vouchedEnabled = () => {
  return process.env.REACT_APP_USE_VOUCHED === 'true'
}

const getFeatureFlags = () => {
  const features = JSON.parse(localStorage.getItem('features'))
  return features !== null ? features : {}
}
