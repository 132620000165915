import { countries } from './countryCodesData'

const countriesAndCodes = () => {
    
  let countriesStructured = []
    
  countriesStructured.push({
      key: 'us', 
      value: 'US', 
      text: 'United States',
      phoneCode : '+1',           
      shortCode : 'US',           
    })
  
  //make Canada Second
  countriesStructured.push({
    key: 'ca', 
    value: 'CA', 
    text: 'Canada',
    phoneCode : '+1',           
    shortCode : 'CA',           
  })

  for(var i=0; i<countries.length; i++) {        
    if(countries[i].name !== 'Canada' && countries[i].name !== 'United States') {
      countriesStructured.push({

        key: countries[i].alpha2code, 
        value: countries[i].alpha2code, 
        text: countries[i].name,
        phoneCode : countries[i].phoneCode,           
        shortCode : countries[i].alpha2code,           
      })
    }
  }
  return countriesStructured
}

export const COUNTRIES = countriesAndCodes()