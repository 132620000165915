export const MARKETING_CALLS = {
    "top-owners": {
        "use_mock": false,
        "mock_endpoint": "",
        "api_type": 'GET',
        "requires_auth" : false
    },
    "latest-reviews": {
        "use_mock": false,
        "mock_endpoint": "",
        "api_type": 'GET',
        "requires_auth" : false
    }
}