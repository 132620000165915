import moment from 'moment-timezone/builds/moment-timezone-with-data'
export const validateURLDates = (startDate, endDate) => {
  var checkInDateTest = moment(startDate)
  var checkOutDateTest = moment(endDate)

  let results = true

  if (checkInDateTest.isValid() && checkOutDateTest.isValid()) {
    results = true
  } else {
    results = false
  }

  return results
}
