import { lazyWithRetry } from 'components/lazyUtil.js'

const RequestDocs = lazyWithRetry(() => import('../../users/sections/uploadRiderDocs'))

const routes = [
  {
    path: '/request_docs/:uuid',
    component: RequestDocs,
    is_private: true,
  }
]

export default routes