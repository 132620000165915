import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import cloudinary from 'cloudinary-core'
import {
  CLOUDINARY_NAME,
  CLOUDINARY_API_KEY,
} from '../../../constants/images/cloudinary'
const cl = new cloudinary.Cloudinary({
  cloud_name: CLOUDINARY_NAME,
  //upload_preset : CLOUDINARY_VEHICLE_PRESET,
  api_key: CLOUDINARY_API_KEY,
})

export const trackViewLoginForm = (view) => {
  mixpanel.track('Viewed Login Form', {
    login_page_type: view.login_type, //modal, page, etc.
  })
}

export const trackLogOut = (doReset) => {
  mixpanel.track('Logged Out')

  if (doReset) {
    mixpanel.reset()
  }
}

export const trackViewSendResetPasswordScreen = (containerType) => {
  mixpanel.track('Send Password Reset Screen Viewed', {
    login_page_type: containerType,
  })
}

export const trackResetPasswordResetEmailAttempt = (params) => {
  mixpanel.track('Password Reset Email Attempted', {
    login_page_type: params.page_type, //modal, page, etc.
    error_message: params.error_messsage,
    error_code: params.error_code,
    error_type: params.error_type, //user, server, other
  })
}

export const trackResetPasswordEmailSent = (pageType) => {
  mixpanel.track('Reset Password Email Sent', {
    login_page_type: pageType,
  })
}

export const trackLogInAndSetInfo = (params, desc) => {
  const { user } = params
  mixpanel.identify(user.user_pretty_id)
  mixpanel.track('Logged In', {
    parent_description: desc,
  })
  mixpanel.people.set({
    'User IDno.': user.user_id,
    'User Pretty ID': user.user_pretty_id,
    'User UUID': user.user_uuid,
    $name: user.first_name + ' ' + user.last_name,
    'Last Login': moment().toISOString(),
    'First Name': user.first_name,
    'Last Name': user.last_name,
    'Trips Taken': user.rides_taken_count,
    'Motorcycles Listed': user.bikes_listed_count,
    'Referrals Given': user.referrals_given_count,
    Referers: user.referers,
    'Is Owner': user.is_owner,
    'Is Rider': user.is_rider,
    'Account City': user.city,
    'Account State': user.state,
    'Account Country': user.country,
    'Country Code': user.country_code,
    Gender: user.gender,
    $phone: user.country_code + user.phone,
    $email: user.email,
    'Phone Verified': user.phone_verified,
    'Email Verified': user.email_verified,
    'Owner Rating': Number(user.owner_rating),
    'Rider Rating': Number(user.rider_rating),
    'Trip Days as Owner': user.total_rental_days_as_owner,
    'Trip Days as Rider': user.total_rental_days_as_rider,
    'Total Spent As Rider': user.total_spent_as_rider_cents / 100,
    'Total Earned As Owner': user.total_earned_as_owner_cents / 100,
    'Free Days': user.free_days,
    'Account Credit': user.account_credits_cents / 100,
    //"Signed Up Through Referral" : ____neeed this____
    //"Referral Code at Sign Up" : ____neeed this______
    'Profile Photo Large': cl.url(params.avatar_cloudinary_public_id, {
      gravity: 'center',
      height: 300,
      width: 300,
      crop: 'fill',
      format: 'jpg',
    }),
    'Profile Photo Small': cl.url(params.avatar_cloudinary_public_id, {
      gravity: 'center',
      height: 125,
      width: 125,
      crop: 'fill',
      format: 'jpg',
    }),
    $avatar: cl.url(params.avatar_cloudinary_public_id, {
      gravity: 'center',
      height: 300,
      width: 300,
      crop: 'fill',
      format: 'jpg',
    }),

    // "Reviews Given As Rider" : params.given_reviews_as_rider,
    // "Reviews Given As Owner"  : params.given_reviews_as_owner,
    // "Reviews Received As Owner" : params.recieved_reviews_as_rider,
    // "Reviews Received As Rider" :  params.recieved_reviews_as_owner,
  })

  mixpanel.people.set_once({
    'Sign Up Date': moment(user.sign_up_date).toISOString(),
  })

  mixpanel.people.increment('Logins')
  mixpanel.identify(user.user_pretty_id)
}

export const trackLogInAttempt = (params) => {
  mixpanel.track('Login Attempt', {
    error_message: params.error_message,
    error_code: params.error_code,
    error_type: params.error_type, //user, server, other
  })
}
