import { Container } from 'unstated'
import { auth } from '../auth/auth'
import { trackViewSignUpForm } from '../elements/tracking/signupTracking.js'
import { trackViewLoginForm } from '../elements/tracking/loginTracking.js'
import {
  createConnection,
  subscribeToChannel,
  closeChannel,
} from '../actionCable'
import * as Cookies from 'js-cookie'

const cable = createConnection()
class AppContainer extends Container {
  state = {
    isAuth: auth.isAuthenticated(),
    userAvatar: auth.userAvatarURL(),
    userAvatarID: auth.userAvatarID(),
    userFullName: auth.userFullName(),
    userID: auth.userID(),
    modalViewType: false,
    modalOpen: false,
    loginCallBackFunction: function() {},
    cable: cable,
    notificationsChannel: subscribeToChannel(
      cable,
      {
        channel: 'NotificationsChannel',
      },
      {
        received: this.handleNotification.bind(this),
      }
    ),
    unreadMessageCount: 0,
    userIsEmailVerified: auth.userIsEmailVerified(),
    userIsPhoneVerified: auth.userIsPhoneVerified(),
    triggeredFrom: '',
  }

  logIn = () => {
    const cable = createConnection()
    this.setState(
      {
        isAuth: true,
        userAvatar: auth.userAvatarURL(),
        userAvatarID: auth.userAvatarID(),
        userFullName: auth.userFullName(),
        userEmail: auth.userEmail(),
        userID: auth.userID(),
        callBackOnClose: false,
        cable: cable,
        notificationsChannel: subscribeToChannel(
          cable,
          {
            channel: 'NotificationsChannel',
          },
          {
            received: this.handleNotification.bind(this),
          }
        ),
        userIsEmailVerified: auth.userIsEmailVerified(),
        userIsPhoneVerified: auth.userIsPhoneVerified(),
      },
      () => {
        if (this.state.loginCallBackFunction) {
          this.state.loginCallBackFunction(auth)
        }
      }
    )
  }

  doLoginCallBackFunction = () => {
    this.state.callBackFunction()
    this.setState({
      loginCallBackFunction: function() {},
    })
  }

  logOut = () => {
    this.setState({
      loginCallBackFunction: function() {},
    })

    closeChannel(this.state.cable, this.state.notificationsChannel)
    this.state.cable.disconnect()
    this.setState({
      isAuth: false,
      userAvatar: auth.userAvatarURL(),
      userAvatarID: auth.userAvatarID(),
      userFullName: auth.userFullName(),
      userID: auth.userID(),
      cable: null,
      notificationsChannel: null,
      unreadMessagesCount: 0,
    })
    auth.logout()
  }

  updateProfilePhoto = () => {
    this.setState({
      userAvatar: auth.userAvatarURL(),
      userAvatarID: auth.userAvatarID(),
    })
  }

  updateFullName = () => {
    this.setState({
      userFullName: auth.userFullName(),
    })
  }

  openLogin = (callBackFunction, callBackOnClose, triggeredFrom) => {
    trackViewLoginForm({ login_type: 'Modal' })
    this.setState({
      modalViewType: 'login',
      modalOpen: true,
      loginCallBackFunction: callBackFunction,
      callBackOnClose: callBackOnClose ? true : false,
      triggeredFrom: triggeredFrom,
    })
  }

  openLoginFromRef = (callBackFunction, refCode) => {
    trackViewLoginForm({ login_type: 'Modal' })
    this.setState({
      modalViewType: 'login',
      modalOpen: true,
      refCode: refCode ? refCode : null,
      loginCallBackFunction: callBackFunction,
    })
  }

  openSignUp = (callBackFunction, refCode) => {
    trackViewSignUpForm({ signup_type: 'Modal' })

    this.setState({
      modalViewType: 'signup',
      modalOpen: true,
      refCode: refCode ? refCode : null,
      loginCallBackFunction: callBackFunction,
    })
  }

  onModalClose = () => {
    this.setState({
      modalViewType: false,
      modalOpen: false,
      loginCallBackFunction: function() {},
      callBackOnClose: false,
    })
  }

  switchLoginModalView = (viewType) => {
    if (viewType === 'signup') {
      trackViewSignUpForm({ signup_type: 'Modal' })
    }
    if (viewType === 'login') {
      //trackViewSignUpForm({ signup_type : 'Modal' })
    }
    this.setState({
      modalViewType: viewType, //login or signup
    })
  }

  handleNotification(notification) {
    if (notification.type === 'unread_messages_count') {
      this.setState({
        unreadMessagesCount: notification.unread_messages_count,
      })
    }
  }

  handlePhoneVerified = () => {
    this.setState({
      userIsPhoneVerified: true,
    })
    Cookies.set('userIsPhoneVerified', true)
  }

  handleEmailVerified = () => {
    this.setState({
      userIsEmailVerified: true,
    })
    Cookies.set('userIsEmailVerified', true)
  }

  async whoAmI() {
    await auth.whoAmI()
    this.setState({
      isAuth: auth.isAuthenticated(),
      userAvatar: auth.userAvatarURL(),
      userAvatarID: auth.userAvatarID(),
      userFullName: auth.userFullName(),
      userID: auth.userID(),
      userIsEmailVerified: auth.userIsEmailVerified(),
      userIsPhoneVerified: auth.userIsPhoneVerified(),
    })
  }
}

export default AppContainer
