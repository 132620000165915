import React, { Component, Suspense } from 'react'
import { lazyWithRetry } from 'components/lazyUtil.js'
import { BrowserRouter, Switch } from 'react-router-dom'
import { Provider, Subscribe } from 'unstated'
import { Columns } from 'components/elements'
import withSizes from 'react-sizes'
import AppContainer from '../components/context/AppContainer'
import { MAP_SIZES_TO_PROPS } from 'constants/utils/mapSizesToProps'
import BuildRoutes from './routes'
import './theme/assets/styles/App.sass'
import './theme/assets/styles/global.sass'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './App.css'
import KlaviyoBanner from './elements/klaviyoBanner'

//Removed lazy loading from header and footer to test the performance
const Header = lazyWithRetry(() => import('../components/theme/header/Header'))
const Footer = lazyWithRetry(() => import('../components/theme/footer/Footer'))
const ScrollToTop = lazyWithRetry(() =>
  import('../components/utils/scrollToTop.js')
)
const ErrorBoundary = lazyWithRetry(() =>
  import('./elements/errors/errorBound.js')
)

class App extends Component {
  constructor() {
    super()
    this.state = {
      redirectToReferrer: false, // may not need this anymore
    }
  }

  render() {
    const { isMobile } = this.props

    const renderLoader = () => (
      <Columns.Column size={12}>
        <div className="has-text-centered" style={{ marginTop: '15%' }}>
          <i className="fas fa-3x fa-cog fa-spin"></i>
        </div>
      </Columns.Column>
    )

    return (
      <Suspense fallback={renderLoader()}>
        <BrowserRouter>
          <Provider>
            <ScrollToTop />
            <Subscribe to={[AppContainer]}>
              {(auth) => {
                return (
                  <>
                    <Header
                      isAuth={auth.state.isAuth}
                      modalViewType={auth.state.modalViewType}
                      unreadMessageCount={auth.state.unreadMessageCount}
                      auth={auth}
                    />
                    <ErrorBoundary>
                      <div className="page-master-container">
                        <KlaviyoBanner />
                        <BuildRoutes
                          isMobile={isMobile}
                          isAuth={auth.state.isAuth}
                          auth={auth}
                        />
                      </div>
                    </ErrorBoundary>
                    <Footer isAuth={auth.state.isAuth} />
                  </>
                )
              }}
            </Subscribe>
          </Provider>
        </BrowserRouter>
      </Suspense>
    )
  }
}

export default withSizes(MAP_SIZES_TO_PROPS)(App)
