import { convertZeroToEmpty, buildBikeURL, parseDateRanges } from '../../utils'
import { reviewsFromRidersAPITransformer } from '../../bikes/calls/reviewFromRidersTransformer'
import {
  motoPhotoID,
  motoPhotoURL,
  userPhotoID,
  userPhotoURL,
} from '../../../constants/images/sizesAndQuality'
import moment from 'moment-timezone'
import { find_price_N_cents } from 'components/search/calls/find_price_N_cents'

export const bikeProfileAPIConnector = async (data) => {
  // special case: for inactive bike
  if (data?.motorcycle?.state == 'inactive') {
    return { 
      status: false,
      message: 'Motorcycle not found.',
      bike_year: data?.motorcycle?.year,
      bike_make: data?.motorcycle?.make,
      bike_model: data?.motorcycle?.model,
      bike_description: data?.motorcycle?.description,
      bikeID: data?.motorcycle?.pretty_id,
      bike_location: {
        location_region_abbr: data?.motorcycle?.location?.region_abbreviation || '',
        location_city: data?.motorcycle?.location?.city || '',
      },
      bike_url: buildBikeURL({
        country: data?.motorcycle?.location?.country_abbreviation,
        region: data?.motorcycle?.location?.region_abbreviation,
        city: data?.motorcycle?.location?.city,
        year: data?.motorcycle?.year.toString(),
        make: data?.motorcycle?.make,
        model: data?.motorcycle?.model,
        bikeID: data?.motorcycle?.pretty_id,
      })
    }
  }

  let dailyRentalPrice = 0
  let multiday5 = 0
  let multiday10 = 0
  let multiday15 = 0
  let multidayN = 0

  dailyRentalPrice = data.motorcycle.daily_rental_price_cents
  multidayN = await find_price_N_cents(dailyRentalPrice, data.motorcycle.pricing.multi_day_pricing)

  multiday5 = dailyRentalPrice - dailyRentalPrice * (5 / 100)
  multiday10 = dailyRentalPrice - dailyRentalPrice * (10 / 100)
  multiday15 = dailyRentalPrice - dailyRentalPrice * (15 / 100)

  let formattedBikeProfile = {
    bike_id: data.motorcycle.id,
    bike_uuid_id: data.motorcycle.uuid,
    in_wishlist: data.motorcycle.in_wishlist,
    bike_pretty_id: data.motorcycle.pretty_id,
    bike_year: data.motorcycle.year,
    bike_model: data.motorcycle.model,
    bike_make: data.motorcycle.make,
    bike_title: data.motorcycle.make + ' ' + data.motorcycle.model,
    bike_state: data.motorcycle.state,
    bike_dealer_access_restriction: data.motorcycle.dealer_access_restriction,
    bike_dealer_access_token: data.motorcycle.dealer_access_token,
    bike_location: {
      location_region_abbr: data.motorcycle.location.region_abbreviation,
      location_postal_code: data.motorcycle.location.postal_code,
      location_city: data.motorcycle.location.city,
      location_region: data.motorcycle.location.region,
      location_country: data.motorcycle.location.country,
      location_country_abbr: data.motorcycle.location.country_abbreviation,
      search_latitude: data.motorcycle.location.search_latitude,
      search_longitude: data.motorcycle.location.search_longitude,
    },
    bike_info: {
      day_price_cents: dailyRentalPrice,
      day_price_currency: data.motorcycle.daily_rental_price_currency,
      day_price_N_cents: multidayN,
      day_price_5_cents: multiday5,
      day_price_10_cents: multiday10,
      day_price_15_cents: multiday15,

      has_multiday: data.motorcycle.pricing.multi_day_discounts,
      multi_day_pricing: data.motorcycle.pricing.multi_day_pricing,
    },
    bike_category: data.motorcycle.category
      ? data.motorcycle.category
      : 'other',
    can_deliver: data.motorcycle.can_deliver,
    flat_delivery_fees_cents:
      data.motorcycle.flat_delivery_fees.flat_delivery_fees_cents,
    max_delivery_distance_in_miles:
      data.motorcycle.max_delivery_distance_in_miles,
    price_per_mile_cents: data.motorcycle.price_per_mile.price_per_mile_cents,
    bike_nickname: data.motorcycle.nickname,
    bike_weight: convertZeroToEmpty(data.motorcycle.weight_lbs),
    bike_engine: convertZeroToEmpty(data.motorcycle.displacement_cc),
    power: data.motorcycle.power,
    torque: data.motorcycle.torque,
    transmission_type: data.motorcycle.transmission_type,
    engine_type: data.motorcycle.engine_type,
    fuel_system: data.motorcycle.fuel_system,
    transmission_type: data.motorcycle.transmission_type,
    wet_weight_lbs: data.motorcycle.wet_weight_lbs,
    tire_tread_formatted: data.motorcycle.tire_tread_formatted,
    tire_treads: data.motorcycle.tire_treads,
    last_inspection_date_formatted:
      data.motorcycle.last_inspection_date_formatted,
    last_serviced_date_formatted: data.motorcycle.last_serviced_date_formatted,
    bike_seat_height: convertZeroToEmpty(data.motorcycle.seat_height_in),
    bike_description: data.motorcycle.description,
    bike_odometer: convertZeroToEmpty(data.motorcycle.odometer_miles),
    bike_parking: data.motorcycle.parking_type,
    partner_allow_messaging:
      data.motorcycle.partner_info.partner_allow_messaging,
    partner_allow_under_25: data.motorcycle.partner_info.partner_allow_under_25,
    bike_fueltank: convertZeroToEmpty(data.motorcycle.fuel_capacity_gal),
    bike_distance_refuel: convertZeroToEmpty(data.motorcycle.fuel_capacity_mi),
    bike_daily_price_cents:
      data.motorcycle.pricing.lowest_daily_rental_price
        .lowest_daily_rental_price_cents, //data.motorcycle.daily_rental_price_cents,
    bike_daily_price_currency:
      data.motorcycle.pricing.lowest_daily_rental_price
        .lowest_daily_rental_price_currency,
    bike_lowest_daily_rental_price:
      data.motorcycle.pricing.lowest_daily_rental_price
        .lowest_daily_rental_price_cents,
    multiday_discount_applied: data.initial_pricing.multi_day_discount_applied,
    base_bike_daily_price_cents: data.motorcycle.daily_rental_price_cents,
    base_bike_daily_price_currency: data.motorcycle.daily_rental_price_currency,
    bike_multi_day_pricing: data.motorcycle.pricing.multi_day_pricing,
    bike_overall_rating: data.motorcycle.reviews_average,
    bike_review_count: data.motorcycle.reviews_count,
    bike_guest_reviews:
      data.motorcycle.reviews.length > 0
        ? reviewsFromRidersAPITransformer(data.motorcycle.reviews)
        : [],
    bike_timezone: data.motorcycle.is_partner
      ? data.motorcycle?.partner_info?.timezone
      : data.motorcycle.timezone,
    bike_blackout_dates: parseDateRanges(
      data.motorcycle.motorcycle_blackout_dates
    ),
    gear: data.motorcycle.gear,
    bike_owner_name:
      data.motorcycle.owner.first_name +
      ' ' +
      data.motorcycle.owner.last_name_initial,
    bike_owner_first_name: data.motorcycle.owner.first_name,
    bike_owner_last_name: data.motorcycle.owner.last_name,
    bike_owner_email_verified: data.motorcycle.owner.verified_email,
    bike_owner_phone_verified: data.motorcycle.owner.verified_phone,
    bike_owner_rating: data.motorcycle.owner.reviews.ratings_average,
    bike_owner_review_count: data.motorcycle.owner.reviews.ratings_count,
    bike_owner_id: data.motorcycle.owner.pretty_id,
    bike_owner_uuid: data.motorcycle.owner.uuid,
    bike_owner_avatar: userPhotoURL(data.motorcycle.owner.avatar.url),
    bike_owner_avatar_cloudinary_public_id: userPhotoID(
      data.motorcycle.owner.avatar.cloudinary_public_id
    ),
    twenty_point_inspected: data.motorcycle.twenty_point_inspected,
    is_newly_listed: data.motorcycle.is_newly_listed,
    can_book_instantly: data.motorcycle.can_book_instantly,
    featured: data.motorcycle.featured,
    trips_taken: data.motorcycle.trips_taken_count,
    listed_at: data.motorcycle.listed_at,
    bike_images: [],
    bike_url: buildBikeURL({
      country: data.motorcycle.location.country_abbreviation,
      region: data.motorcycle.location.region_abbreviation,
      city: data.motorcycle.location.city,
      year: data.motorcycle.year.toString(),
      make: data.motorcycle.make,
      model: data.motorcycle.model,
      bikeID: data.motorcycle.pretty_id,
    }),
    owner_link: '/users/' + data.motorcycle.owner.pretty_id,
    is_partner: data.motorcycle.is_partner,
    for_sale: data.motorcycle.purchase_information.purchasable,
    closed_days: [],
    chat_session: data.chat_session,
    working_hours: data.motorcycle.working_hours,
    pickup_timings: data.motorcycle.pickup_timings,
    dropoff_timings: data.motorcycle.dropoff_timings,
    owner_response_time: data.owner_response_time,
    nearby_events: data.nearby_events,
  }

  for (let i = 0; i < data.motorcycle.images.length; i++) {
    formattedBikeProfile.bike_images.push({
      bike_image_url: motoPhotoURL(data.motorcycle.images[i].url).replace(
        'image/upload/',
        'image/upload/c_fill,g_auto,h_600,w_900,q_auto,f_auto/'
      ),
      bike_image_cloudinary_public_id: motoPhotoID(
        data.motorcycle.images[i].cloudinary_public_id
      ),
    })
  }

  formattedBikeProfile['same_day_available'] = data.motorcycle.same_day_rentable
  formattedBikeProfile['same_day_rental_notice_hours'] =
    data.motorcycle.same_day_rental_notice_hours

  if (data.motorcycle.is_partner) {
    formattedBikeProfile['bike_owner_name'] =
      data.motorcycle.partner_info.partner_name
    formattedBikeProfile['partner_type'] =
      data.motorcycle.partner_info.partner_type
    formattedBikeProfile['owner_link'] =
      '/partners/' + data.motorcycle.owner.pretty_id
    formattedBikeProfile['same_day_available'] = evalPartnerSameDayRental(
      data.motorcycle.same_day_rentable,
      data.motorcycle.partner_info.partner_working_hours,
      data.motorcycle.timezone
    )
    formattedBikeProfile['partner_hours'] =
      data.motorcycle.partner_info.partner_working_hours
    formattedBikeProfile['closed_days'] = makeClosedDays(
      data.motorcycle.partner_info.partner_working_hours
    )
  } else {
    formattedBikeProfile['closed_days'] = makeClosedDays(
      data.motorcycle?.working_hours
    )
  }

  if (data.motorcycle.purchase_information.purchasable) {
    formattedBikeProfile['sale_price_cents'] =
      data.motorcycle.purchase_information.purchase_price_cents
    formattedBikeProfile['sale_price_currency'] = data.motorcycle
      .purchase_information.purchase_price_currency
      ? data.motorcycle.purchase_information.purchase_price_currency
      : 'USD'
    formattedBikeProfile['sale_price_monthly_cents'] =
      data.motorcycle.purchase_information.monthly_price_cents
    formattedBikeProfile['sale_price_monthly_currency'] =
      data.motorcycle.purchase_information.monthly_price_currency
  }

  formattedBikeProfile['has_multiday_discount'] =
    data.motorcycle.pricing.multi_day_discounts

  let performance = {}

  if (data.motorcycle.odometer_miles) {
    performance.odometer_miles = convertZeroToEmpty(
      data.motorcycle.odometer_miles
    )
  }

  if (data.motorcycle.fuel_capacity_gal) {
    performance.fuel_capacity_gal = convertZeroToEmpty(
      data.motorcycle.fuel_capacity_gal
    )
  }

  if (data.motorcycle.fuel_capacity_mi) {
    performance.fuel_capacity_mi = convertZeroToEmpty(
      data.motorcycle.fuel_capacity_mi
    )
  }

  formattedBikeProfile['performance'] = performance

  return formattedBikeProfile
}

export const makeClosedDays = (partnerHours) => {
  let closedDays = []
  partnerHours.forEach((entry, index) => {
    if (entry.holiday) {
      closedDays.push(entry.day)
    }
  })
  return closedDays
}

const evalPartnerSameDayRental = (
  sameDayRentable,
  workingHours,
  motoTimezone
) => {
  if (!workingHours) {
    return sameDayRentable
  }

  if (workingHours.length === 0) {
    return sameDayRentable
  }

  const todayDayOfWeek = moment()
    .tz(motoTimezone)
    .format('dddd') //what day is is? Monday?

  const todayTime = moment()
    .tz(motoTimezone)
    .format('hA') //what time is it?

  const dayWeekIndex = workingHours.findIndex((x) => x.day === todayDayOfWeek) // find this day's (Monday's) Hours.

  if (workingHours[dayWeekIndex].holiday) {
    //if it's closed, it's closed.
    return false
  }

  //we aren't closed today, so let's find the closing time.
  const nowMiltaryTime = moment(todayTime, 'hA').format('H')
  //console.log(nowMiltaryTime)
  const closingMiltaryTime = moment(
    workingHours[dayWeekIndex].end_at_short,
    'hA'
  ).format('H')
  //.log(closingMiltaryTime)
  if (parseInt(nowMiltaryTime) >= parseInt(closingMiltaryTime)) {
    return false //it's closed now. sorry.
  }

  return true ///if we make it through everything then we do have sameday thang.
}
