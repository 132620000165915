import React, { useState, useEffect } from "react"
import { auth } from "components/auth/auth"
import './klaviyoBanner.css'

function KlaviyoBanner(props) {
  const [isFixed, setIsFixed] = useState(false)
  const [bannerHeight, setBannerHeight] = useState(0)
  const isLoggedIn = auth.isAuthenticated()
  const isTripTaken = Number(auth.userRideTakenCount()) > 0

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY
      setIsFixed(scrollPosition > 0 )
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const banner = document.querySelector('.klaviyo-form-VkeP7q');
    if (banner) {
      setBannerHeight(banner.offsetHeight);
    }
  }, [isFixed]);

  return (
    <React.Fragment>
      {(!isLoggedIn || !isTripTaken) && (
        <>
          {isFixed && <div style={{ height: bannerHeight }}></div>}
          <div style={{
            position: isFixed ? 'fixed' : 'relative',
            top: isFixed ? 0 : 'auto',
            width: '100%',
            zIndex: 999
          }}>
            <div
              className="klaviyo-form-VkeP7q"
            ></div>
          </div>
        </>
      )}
    </React.Fragment>
  )
}

export default KlaviyoBanner
