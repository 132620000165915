import { countries } from "./countryCodesData";

const countrieAndPhoneCodes = () => {
  let countriesPhoneStructured = []

  //make US First
  countriesPhoneStructured.push({
    key: "us",
    value: "+1",
    text: "United States",
    phoneCode: "+1",
    shortCode: "US",
  })

  //make Canada Second
  countriesPhoneStructured.push({
    key: "ca",
    value: "+1",
    text: "Canada",
    phoneCode: "+1",
    shortCode: "CA",
  })

  for (var i = 0; i < countries.length; i++) {
    if (
      countries[i].name !== "Canada" &&
      countries[i].name !== "United States"
    ) {
      countriesPhoneStructured.push({
        key: i,
        value: countries[i].phoneCode,
        text: countries[i].name,
        phoneCode: countries[i].phoneCode,
        shortCode: countries[i].alpha2code,
      })
    }
  }
  return countriesPhoneStructured
};

export const WORLD_COUNTRY_CODES = countrieAndPhoneCodes()
