const parkingTypesMasterList = [
  'none_indicated',
  'driveway',  
  'street',
  'no_parking'
]

const parkingTypesMasterListText = [
  '(None Indicated)',
  'Driveway Parking Available',
  'Street Parking Available',
  'No Parking Available',
]
  
function bikeParkingList() {
  let bikeParkingArray = [];

  for (let x = 0; x < parkingTypesMasterList.length; x++) {
    bikeParkingArray.push({
      key: x,
      value: parkingTypesMasterList[x],
      text: parkingTypesMasterListText[x],
    });
  }

  return bikeParkingArray;
}

export const BIKE_PARKING = bikeParkingList();
export const BIKE_PARKING_RAW = parkingTypesMasterList;