import { reservationsTransformer } from '../transformers/reservationsTransformer'
import { reservationsDetailsFormatted } from '../transformers/reservationDetailsTransformer.js'

export const RESERVATION_CALLS = {
  'reservations-owner': {
    use_mock: false,
    mock_endpoint: 'reservations/user/U111AAA',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return reservationsTransformer(data)
    },
  },
  'reservations-details': {
    use_mock: false,
    mock_endpoint: 'reservations/user/U111AAA/details/TR111',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return reservationsDetailsFormatted(data)
    },
  },
  'approve-ride': {
    use_mock: false,
    mock_endpoint: 'reservations/accept',
    api_type: 'POST',
    requires_auth: true,
  },
  'reject-ride': {
    use_mock: false,
    mock_endpoint: 'reservations/reject',
    api_type: 'POST',
    requires_auth: true,
  },
  'start-trip': {
    use_mock: false,
    mock_endpoint: 'reservations/start/TR111AAA/owner/U111AAA',
    api_type: 'POST',
    requires_auth: true,
  },
  'end-trip': {
    use_mock: false,
    mock_endpoint: 'reservations/end/TR111AAA/owner/U111AAA',
    api_type: 'POST',
    requires_auth: true,
  },
  'report-damage': {
    use_mock: false,
    mock_endpoint: 'reservations/report-damage',
    api_type: 'POST',
    requires_auth: true,
  },
  'approve-ride-modification-request': {
    use_mock: false,
    mock_endpoint: 'reservations/TR111AAA/ride_modification_requests/1/approve',
    api_type: 'POST',
    requires_auth: true,
  },
  'reject-ride-modification-request': {
    use_mock: false,
    mock_endpoint: 'reservations/TR111AAA/ride_modification_requests/1/reject',
    api_type: 'POST',
    requires_auth: true,
  },
}
