export const ADVERTISEMENT_CALLS = {
  "search-advertisement" : {
      "use_mock": false,
      "mock_endpoint": "advertisement/for_search",
      "api_type": "GET",
      "requires_auth" : false
  },
  "blog-advertisement" : {
      "use_mock": false,
      "mock_endpoint": "advertisement/for_blog",
      "api_type": "GET",
      "requires_auth" : false
  },
}