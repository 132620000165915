import { userPhotoID, userPhotoURL } from '../../../constants/images/sizesAndQuality'

export const userProfilePhotoTransformer = (data) => {

    let formattedProfilePhoto = {
     
        "profile_image_url" : userPhotoURL(data.user.user_avatar.url),
        "profile_image_cloudinary_public_id" : userPhotoID(data.user.user_avatar.cloudinary_public_id)
    }


    

return formattedProfilePhoto

}
