// const axios = require('axios');
import _ from 'lodash'
import moment from 'moment'

import { motoPhotoID, motoPhotoURL } from '../../../constants/images/sizesAndQuality'
import { find_price_N_cents } from '../../../components/search/calls/find_price_N_cents.js'

export const motorcycleEventsTransformer = async (data) => {
  const motorcycleEvent = data?.motorcycle_event
  const nearbyBikes = await nearbyBikesTransformer(data.motorcycles)

  return {
    motorcycle_event: motorcycleEvent,
    nearby_motorcycles: nearbyBikes.bikes,
    motorcycles_meta: data.motorcycles_meta
  }
}

export const motorcycleEventsSearchTransformer = (data) => {
  const motorcycleEventsByMonth = groupEventsByMonth(data.results)

  return {
    motorcycle_events_by_month: motorcycleEventsByMonth,
    results: data.results,
    results_meta: data.results_meta,
    results_score: data.results_score
  }
}

const groupEventsByMonth = (motorcycleEvents) => {
  return _.groupBy(motorcycleEvents, ({ start_date }) => moment(start_date).format('MMMM YYYY'))
}

const nearbyBikesTransformer = async (data) => {
  let nearbyBikes = {
    bikes: []
  }

  //setting up variables for multiday hack

  let dailyRentalPrice = 0
  let multiday5 = 0
  let multiday10 = 0
  let multiday15 = 0
  let multidayN = 0


  for (let i = 0; i < data.length; i++) {

    dailyRentalPrice = data[i].daily_rental_price_cents
    multidayN = await find_price_N_cents(dailyRentalPrice, data[i]?.pricing?.multi_day_pricing)
    multiday5 = dailyRentalPrice - (dailyRentalPrice * (5 / 100))
    multiday10 = dailyRentalPrice - (dailyRentalPrice * (10 / 100))
    multiday15 = dailyRentalPrice - (dailyRentalPrice * (15 / 100))//data.results[i].pricing.lowest_daily_rental_price.lowest_daily_rental_price_cents

    nearbyBikes.bikes.push(

      {

        //multidayHackData
        "day_price_cents": dailyRentalPrice,
        "day_price_currency": data[i].daily_rental_price_currency,
        "day_price_N_cents": multidayN,
        "day_price_5_cents": multiday5,
        "day_price_10_cents": multiday10,
        "day_price_15_cents": multiday15,

        "has_multiday": data[i].pricing.multi_day_discounts,
        "multi_day_pricing": data[i].pricing.multi_day_pricing,
        //Previous Data

        "bike_id": data[i].pretty_id,
        "bike_year": data[i].year,
        "bike_make": data[i].make,
        "bike_model": data[i].model,
        "bike_location": {
          "city": data[i].location.city,
          "region": data[i].location.region,
          "region_abbreviation": data[i].location.region_abbreviation,
          "country": data[i].location.country,
          "country_abbreviation": data[i].location.country_abbreviation,
        },
        // "day_price_cents": data[i].daily_rental_price_cents,
        //"day_price_currency": data[i].daily_rental_price_currency,
        "bike_owner_first_name": data[i].owner ? data[i].owner.first_name + " " + data[i].owner.last_name_initial : null,
        "bike_image_cloudinary": motoPhotoURL(data[i].images[0].url),
        "bike_image_cloudinary_public_id": motoPhotoID(data[i].images[0].cloudinary_public_id),
        'average_rating': data[i].reviews_average,
        'ratings_count': data[i].reviews_count
      }

    )
  }
  return nearbyBikes
}