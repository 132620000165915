//bikeCategoriesRaw
export const BIKE_CATEGORIES_ARRAY = [
    "adventure",
    "cruiser",
    "sport",
    "standard",
    "touring",
    "all_styles"
  ]
//bikeCategories
  export const BIKE_CATEGORIES = [
    {  
        catLabel : "Adventure",
        catName : "adventure",
        catIconUrl: '/static/images/styleFilterIcons/adventure.svg'
    },    
    {  
        catLabel : "Cruiser",
        catName : "cruiser",
        catIconUrl: '/static/images/styleFilterIcons/cruiser.svg'
    },    
    {  
        catLabel : "Sport",
        catName : "sport",
        catIconUrl: '/static/images/styleFilterIcons/sport.svg'

    },    
    {  
        catLabel : "Standard",
        catName : "standard",
        catIconUrl: '/static/images/styleFilterIcons/standard.svg'
    },    
    {  
        catLabel : "Touring",
        catName : "touring",
        catIconUrl: '/static/images/styleFilterIcons/touring.svg'
    },    
    {  
        catLabel : "All Styles",
        catName : "all_styles",
        catIconUrl: '/static/images/styleFilterIcons/others.svg'
    }

]
