import {
  API_URL,
  MOCK_URL,
  BUILD_ENV,
  //API_CONSOLE_DEBUG
} from '../../constants'
import { CALL_LIBRARY } from './callLibrary/callLibrary.js'
import * as Cookies from 'js-cookie'
import { apiGET } from './calls/apiGET.js'
import { apiPOST } from './calls/apiPOST.js'
import { apiDELETE } from './calls/apiDELETE.js'
import { apiPUT } from './calls/apiPUT.js'
import { apiPATCH } from './calls/apiPATCH.js'
import { unauthenticate } from '../auth/auth'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

const axios = require('axios')

export const apiDebug = (desc, data) => {
  console.log(desc, data)
}

const refreshAuthLogic = (failedRequest) => {
  //console.log('refresh fired')
  return axios
    .post(
      API_URL + 'users/sessions/refresh/',
      {
        refresh_token: Cookies.get('userRefreshToken') ? Cookies.get('userRefreshToken') : 'notoken',
      },
      { skipAuthRefresh: true },
    )
    .then((tokenRefreshResponse) => {
      //console.log('refresh token fired. now retrying failed call')
      Cookies.set('userToken', tokenRefreshResponse.data.token)
      failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token
      return Promise.resolve()
    })
}

//Here we catch bad tokens and
axios.interceptors.response.use(
  function(response) {
    return response
  },
  function(error) {
    if (error.response.data.error_messages) {
      //console.log(error)
      if (error.response.data.error_messages[0] === 'Invalid Refresh Token.') {
        //If it's a bad refresh token, we remove this error message as to not display it
        //then we just log them out
        delete error.response.data.error_messages
        unauthenticate(true)
      }
    }
    return Promise.reject(error)
  },
)

createAuthRefreshInterceptor(axios, refreshAuthLogic)

export const apiProcess = (urlSuffix, data, processName, componentDesc) => {
  //console.log(urlSuffix)
  //console.log(data)

  //if we can't find the process name, we abort early. This is a dev helper only.
  if (!CALL_LIBRARY.hasOwnProperty(processName) && BUILD_ENV === 'development') {
    apiDebug('ERROR: ', 'No Process Name')
    return Promise.reject({
      errorCode: 'n/a',
      errorResponseData: {
        error_messages: ['No API Process Name Found in Call Library'],
      },
    })
  }
  // eslint-disable-next-line
  let useMock = false //we assume we aren't using mock be default
  let API_Prefix = API_URL //whatever the env API URL, we overwrite this later if the processName's mock bit in callLibrary is set to TRUE

  //we set up the base header here
  let apiConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  //if we do have a cookie, then attach it regardless of if we need it our not
  if (Cookies.get('userToken')) {
    apiConfig['headers']['Authorization'] = 'Bearer ' + Cookies.get('userToken')
  }

  if (!CALL_LIBRARY[processName].requires_auth) {
    apiConfig['skipAuthRefresh'] = true
  }

  //we set the apiTYPE
  const apiType = CALL_LIBRARY[processName].api_type

  //check Mock Mode
  if (CALL_LIBRARY[processName].use_mock === true || localStorage?.getItem('twisted-mock') === 'use-mock') {
    //if global mock mode or local processName mock mode is on
    if (!CALL_LIBRARY[processName].hasOwnProperty('over_ride_global_mock')) {
      //this bit will always force a staging or production server call and never user postman. Good for things like 3rd party API calls that can not be mocked.
      useMock = true
      API_Prefix = MOCK_URL
      urlSuffix = CALL_LIBRARY[processName].mock_endpoint
    }
  }

  //encode the URI
  let encodedURI = window.encodeURI(API_Prefix + urlSuffix)

  if (apiType === 'GET') {
    if (data) {
      apiConfig['params'] = data
    }

    return apiGET(data, apiConfig, encodedURI, processName, componentDesc, axios)
      .then(function(response) {
        return response
      })
      .catch(function(error) {
        throw error
      })
  }

  if (apiType === 'POST') {
    return apiPOST(data, apiConfig, encodedURI, processName, componentDesc, axios)
      .then(function(response) {
        return response
      })
      .catch(function(error) {
        throw error
      })
  }

  if (apiType === 'DELETE') {
    if (data) {
      apiConfig['data'] = data
    }
    return apiDELETE(data, apiConfig, encodedURI, processName, componentDesc, axios)
      .then(function(response) {
        return response
      })
      .catch(function(error) {
        throw error
      })
  }

  if (apiType === 'PATCH') {
    return apiPATCH(data, apiConfig, encodedURI, processName, componentDesc, axios)
      .then(function(response) {
        return response
      })
      .catch(function(error) {
        throw error
      })
  }

  if (apiType === 'PUT') {
    return apiPUT(data, apiConfig, encodedURI, processName, componentDesc, axios)
      .then(function(response) {
        return response
      })
      .catch(function(error) {
        throw error
      })
  }
}
