import { apiErrorHandling } from "../apiErrorHandling";

export const apiPATCH = (data, config, url, processName, componentDesc, axios) => {
    return axios
    .patch(url, data, config)
    .then(function(response) {
      return response.data;
    })
    .catch(function(error) {
        throw apiErrorHandling(
            "PATCH", 
            error, 
            data, 
            componentDesc,
            processName, 
            url
    )
    });
}




