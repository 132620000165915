const provinces = require('provinces')

const canadaProvinces = () => {
  let statesProvinces = []
  for(var i=0; i<provinces.length; i++) {
    if(provinces[i].country === 'CA') {
      statesProvinces.push({
        key: provinces[i].short,
        value: provinces[i].short,
        text: provinces[i].name           
      })
    }
  }
  return statesProvinces
}

export const CANADA_PROVINCES = canadaProvinces()