export const loadDynamicScript = (url, id, callback) => {
    const existingScript = document.getElementById(id);
  
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = url; // URL for the third-party library being loaded.
      script.id = id; // e.g., googleMaps or stripe
      if (id == 'vouched') {
        script.setAttribute('crossorigin', '')
      }
      document.body.appendChild(script);
  
      script.onload = () => {
        if (callback) callback();
      };
    }
  
    if (existingScript && callback) callback();
  }