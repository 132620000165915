import { auth } from '../auth/auth'
import { createConsumer } from "@rails/actioncable";
import * as Cookies from 'js-cookie'
import { WS_URL } from '../../constants'

export const createConnection = () => {
  let cable = null
  if (auth.isAuthenticated()) {
    const token = Cookies.get('userToken')
    cable = createConsumer(`${WS_URL}?token=${token}`)
  }
  return cable
}

export const subscribeToChannel = (cable, data, handlers) => {
  if (cable) {
    return cable.subscriptions.create(data, handlers)
  }
  return null
}

export const closeChannel = (cable, channel) => {
  if (cable) {
    return cable.subscriptions.remove(channel)
  }
}
