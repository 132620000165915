import { lazyWithRetry } from 'components/lazyUtil.js'

const ListBikeForm = lazyWithRetry(() => import('../../list/motorcycle/create/listMotorcycleFormWizard.js'))

const routes = [
  {
    path: '/list/motorcycle/:step?',
    component: ListBikeForm,
    private: true,
  },
  {
    path: '/list/motorcycle/edit/:bikeID/:step?',
    component: ListBikeForm,
    private: true,
  },
]

export default routes
