import { userPaymentMethodsTransformer } from '../transformers/userPaymentMethodsTransformer.js'
import { getPaymentTransformer, getTripModificationTransformer } from '../../payments/booking/calls/getPaymentTransformer.js'

export const PAYMENTS_CALLS = {
  "delete-payment-method": {
    "use_mock": false,
    "mock_endpoint": "account/payment-method/delete",
    "api_type": 'DELETE',
    "requires_auth": true
  },
  "add-payment-method": {
    "use_mock": false,
    "mock_endpoint": "account/payment-method/add",
    "api_type": 'POST',
    "requires_auth": true
  },
  "payment-methods": {
    "use_mock": false,
    "mock_endpoint": "account/U111AAA/edit-payment-methods",
    "api_type": 'GET',
    "requires_auth": true,
    transFormData(data) {
      return userPaymentMethodsTransformer(data)
    }
  },
  "payouts-page": {
    "use_mock": false,
    "mock_endpoint": "account/U111AAA/edit-payout-settings",
    "api_type": 'GET',
    "requires_auth": true
  },
  "form-w9-validation": {
    "use_mock": false,
    "mock_endpoint": "account/U111AAA/edit-payout-settings",
    "api_type": 'GET',
    "requires_auth": true
  },
  "payouts-page-save": {
    "use_mock": false,
    "mock_endpoint": "account/U111AAA/edit-payout-settings",
    "api_type": 'PUT',
    "requires_auth": true
  },
  "payment-screen": {
    "use_mock": false,
    "mock_endpoint": "payments/book/TR111AAA",
    "api_type": 'GET',
    "requires_auth": true,
    transFormData(data) {
      return getPaymentTransformer(data)
    }
  },
  "calculate-total": {
    "use_mock": false,
    "mock_endpoint": "calculatepaymentooooo",
    "api_type": 'GET',
    "requires_auth": false
  },
  "process-payment": {
    "use_mock": false,
    "mock_endpoint": "payments/book/process",
    "api_type": 'POST',
    "requires_auth": true
  },
  "create-reservation": {
    "use_mock": false,
    "mock_endpoint": "reservations/create",
    "api_type": 'POST',
    "requires_auth": true
  },
  "calculate-request-modification-total": {
    "use_mock": false,
    "mock_endpoint": "calculatepaymentooooo",
    "api_type": 'GET',
    "requires_auth": false,
    transFormData(data) {
      return getTripModificationTransformer(data)
    }
  },
  "submit-w9" : {
    "use_mock": false,
    "mock_endpoint": "tax1099/w9",
    "api_type": 'POST',
    "requires_auth": true
  }
}
