export const referralsInviteTransformer = (data) => {
  let referralsPage = {
    /*"refferal_share_id": "jamesw1986",
            "rental_credit_cents": "40000",
            "rental_currency" : "USD",
            "friends_signed_up": 8,
            "friends_rented_bikes": 8*/
  }

  return referralsPage
}
