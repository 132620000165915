import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Field, Label, Control, Help, Input, Textarea } from 'react-bulma-components/lib/components/form'
import { ErrorMessage } from 'formik'
import Select from './select'
// import input from "react-bulma-components/lib/components/form/components/input"
let classNames = require('classnames')
const InlineError = ({ name }) => <ErrorMessage component={Help} color="danger" name={name} />

/*TODO: 
- Switches, disabled state
- Radio: We haven't used these, yet. They'll need to be written for. 
-- Disabled states
*/

const findColor = (error, warning, success) => {
  if (error) {
    return 'danger'
  } else if (warning) {
    return 'warning'
  } else if (success) {
    return 'success'
  } else {
    return null
  }
}

const InputWrap = (props) => (
  <Input
    id={props.id}
    name={props.name}
    className={props.inputClassName}
    value={props.value}
    onChange={(e) => props.onChange({ e, value: e.target.value })}
    fullwidth={props.fullwidth.toString()}
    color={findColor(props.error, props.warning, props.success)}
    size={props.size}
    type={props.textInputType}
    disabled={props.disabled}
    placeholder={props.placeholder}
    autoComplete={props.autoComplete}
    onBlur={props.onBlur}
    onFocus={props.onFocus}
  />
)

const TextareaWarp = (props) => (
  <Textarea
    id={props.id}
    name={props.name}
    className={props.inputClassName}
    value={props.value}
    disabled={props.disabled}
    onChange={(e) => props.onChange({ e, value: e.target.value })}
    fullwidth={props.fullwidth.toString()}
    color={findColor(props.error, props.warning, props.success)}
    placeholder={props.placeholder}
    rows={props.rows}
    onBlur={props.onBlur}
    onFocus={props.onFocus}
  />
)

const SwitchWrap = (props) => {
  let switchClass = classNames('switch is-rounded', props.inputClassName, {
    'is-danger': props.error,
    'is-warning': props.warning,
    'is-success': props.success,
    'is-primary': !props.error && !props.success && !props.warning,
  })

  return (
    <>
      <input
        id={props.id}
        type="checkbox"
        name={props.name}
        className={switchClass}
        checked={props.checked ? 'checked' : ''}
        onChange={(e) => props.onChange({ e, checked: e.target.checked })}
      />
      <Label for={props.name} className={'py-0 ' + props.labelclassname}>
        {props.label}
      </Label>
    </>
  )
}

const CheckBoxWrap = (props) => {
  let checkboxClass = classNames('is-checkradio', props.inputClassName, {
    'has-background-color is-primary': props.checked,
  })

  return (
    <>
      <input
        className={checkboxClass}
        id={props.id}
        type="checkbox"
        name={props.name}
        value={props.value}
        checked={props.checked ? 'checked' : ''}
        onChange={(e) =>
          props.onChange({
            e,
            [props.checkedReturn === 'boolean' ? 'checked' : 'value']:
              props.checkedReturn === 'boolean' ? e.target.checked : e.target.value,
          })
        }
      />
      <Label for={props.name} className={'py-0 ' + props.labelclassname}>
        {props.label}
      </Label>
    </>
  )
}

const SelectWrap = (props) => {
  return (
    <Select
      className={props.className}
      placeholder={props.placeholder}
      fullwidth
      labelclassname="is-size-7"
      name={props.name}
      id={props.name}
      disabled={props.disabled}
      color={findColor(props.error, props.warning, props.success)}
      onChange={(e) => props.onChange({ e, value: e.target.value })}
      value={props.value}
      options={props.options} //setting a default in case this is null in data
    />
  )
}

const Fields = (props) => ({
  input: {
    component: <InputWrap {...props} />,
  },
  textarea: {
    component: <TextareaWarp {...props} />,
  },
  switch: {
    component: <SwitchWrap {...props} />,
    hideTopLabel: true,
  },
  checkbox: {
    component: <CheckBoxWrap {...props} />,
    hideTopLabel: true,
  },
  select: {
    component: <SelectWrap {...props} />,
  },
})

class FieldWrap extends Component {
  render() {
    const {
      fullwidth,
      hasAddOns,
      name,
      align,
      type,
      labelclassname,
      size,
      label,
      iconLeft,
      iconRight,
      showErrors,
      wrapClassName,
      subLabel,
    } = this.props

    //should look at and refactor this
    let controlClass = fullwidth || hasAddOns ? 'is-expanded' : null

    let labelClass = classNames('', labelclassname, {
      'mb-0': subLabel,
    })

    return (
      <>
        <Field className={wrapClassName} fullwidth={fullwidth.toString()} align={align ? align : null}>
          {!Fields(this.props)[type].hideTopLabel && label && (
            <>
              <Label htmlFor={name} className={labelClass} size={size}>
                {label}
              </Label>
              {subLabel && <p className="mb-2 has-text-grey">{subLabel}</p>}
            </>
          )}
          <MixInAddOn {...this.props}>
            <Control iconLeft={iconLeft} iconRight={iconRight} className={controlClass}>
              {Fields(this.props)[type]['component']}
              {iconLeft && (
                <span className={`icon is-${size} is-left`}>
                  <i className={iconLeft}></i>
                </span>
              )}
              {iconRight && (
                <span className={`icon is-${size} is-right`}>
                  <i className={iconRight}></i>
                </span>
              )}
            </Control>
          </MixInAddOn>
          {showErrors && <InlineError name={name} />}
        </Field>
      </>
    )
  }
}

const MixInAddOn = ({ children, hasAddOns, rightAddOn, leftAddOn }) => {
  return (
    <>
      {hasAddOns && (
        <Control>
          <Field kind={hasAddOns ? 'addons' : null}>
            {hasAddOns && leftAddOn && <Control>{leftAddOn}</Control>}
            {children}
            {hasAddOns && rightAddOn && <Control>{rightAddOn}</Control>}
          </Field>
        </Control>
      )}

      {!hasAddOns && <>{children}</>}
    </>
  )
}

export default FieldWrap

FieldWrap.defaultProps = {
  type: 'input', //or anything other form elements created above...
  checked: false,
  checkedReturn: 'boolean', //or value
  id: 'id-missing',
  name: 'name-missing',
  onChange: () => {},
  label: null,
  labelclassname: '',
  wrapClassName: '',
  inputClassName: '',
  subLabel: '',
  placeholder: '',
  value: '',
  iconLeft: false, //or icon short code aka: <i> + fas fas-user
  iconRight: false, //or icon short code aka: <i> + fas fas-user
  size: 'normal',
  error: false,
  loading: false,
  disabled: false,
  fullwidth: false,
  hadAddOns: false,
  leftAddOn: null,
  textInputType: 'text',
  rightAddOn: null,
  options: [],
  showErrors: true,
  align: null,
}

FieldWrap.propTypes = {
  type: PropTypes.oneOf(['select', 'light', 'dark']), //or anything other form elements created above...
  checked: PropTypes.bool,
  checkedReturn: PropTypes.oneOf(['boolean', 'value']), //or value
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subLabel: PropTypes.string,
  labelclassname: PropTypes.string,
  wrapClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  iconLeft: PropTypes.node, //or icon short code aka: fas fas-user
  iconRight: PropTypes.node, //or icon short code
  size: PropTypes.oneOf(['small', 'normal', 'medium', 'large']),
  error: PropTypes.bool,
  textInputType: PropTypes.oneOf(['email', 'number', 'password', 'tel', 'text', 'url']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  fullwidth: PropTypes.bool,
  hadAddOns: PropTypes.bool,
  leftAddOn: PropTypes.node,
  rightAddOn: PropTypes.node,
  options: PropTypes.array,
  showErrors: PropTypes.bool,
  align: PropTypes.oneOf(["centered", "right"]),
  autoComplete: PropTypes.oneOf(["on", "off"]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}
