export const GENDER_OPTIONS = [
    {
      key: 'Male',
      text: 'Male',
      value: 'male'
    },
    {
        key: 'Female',
        text: 'Female',
        value: 'female'
      },
      {
        key: 'Other',
        text: 'Other',
        value: 'other',
      },
      {
        key: 'Rather not say',
        text: 'Rather not say',
        value: 'rather not say',
      },
  ]