//Basic Step Key
export const BASIC_SPECS_STEP = 'basic-specs'
export const BASIC_SPECS_DESCRIPTION = 'Motorcycle Technical Specifications'

export const HEIGHT = 'height'
export const WEIGHT = 'weight'
export const ENGINE = 'engine'
export const FUEL_TANK_CAPACITY = 'fuel_tank_capacity'
export const MILES_BEFORE_REFUEL = 'miles_before_refuel'
export const TIRES_TREAD = 'tire_treads'
export const LAST_SERVICE = 'last_serviced_date'

export const TREAD_OPTIONS = [
  { key: 100, value: 100, text: '100%: new tires' },
  { key: 75, value: 75, text: '75%: slightly used' },
  { key: 50, value: 50, text: '50%: fairly worn' },
  { key: 25, value: 25, text: '25%: seen some miles' },
]
