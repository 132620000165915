export const reviewsFromOwnersAPITransformer = (data) => {
  const reviewCleanData = []
  for (let i = 0; i < data.length; i++) {
    reviewCleanData.push({
      of_rider_submitted: data[i].review.of_rider_submitted,
      owner_review_id: null,
      owner_review_name:
        data[i].owner.first_name +
        ' ' +
        data[i].owner.last_initial,
      owner_id: data[i].owner.pretty_id,
      owner_review_date: data[i].review.of_rider.created_at,
      owner_review_avatar: data[i].owner.avatar.url,
      owner_review_avatar_cloudinary_public_id:
        data[i].owner.avatar.cloudinary_public_id,
      owner_review_rating: data[i].review.of_rider.score,
      owner_review_text: data[i].review.of_rider.description,
      owner_review_bike_id: data[i].motorcycle.pretty_id,
      owner_review_bike_make: data[i].motorcycle.make,
      owner_review_bike_model: data[i].motorcycle.model,
      owner_review_bike_year: data[i].motorcycle.year,
      owner_location: data[i].owner.location,
      motorcycle_location: data[i].motorcycle.location,
      motorcycle_active: data[i].motorcycle.state === 'active' ? true : false,
      owner_active: data[i].owner.state === 'active' ? true : false,

      // "location":
    })
  }
  return reviewCleanData
}