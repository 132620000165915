import {
  userPhotoID,
  userPhotoURL,
} from '../../../constants/images/sizesAndQuality'
import { findBikeDistanceScore } from './findDistanceScore.js'

export const mapboxSearchAPIConnector = async (data) => {
  let cleanMapboxSearchData = {
    groupedByOwner: {},
    groupedMarkers: [],
    allCoordinates: [],
    resultsMeta: {}
  }

  for (let i = 0; i < data.results.length; i++) {
    const bike_owner_id = data.results[i].owner.pretty_id
    const { search_latitude, search_longitude } = data.results[i].location
    const tempBikeInfo = {
      day_price_cents: data.results[i].daily_rental_price_cents,
      day_price_currency: data.results[i].daily_rental_price_currency,

      bike_id: data.results[i].pretty_id,
      is_partner: data.results[i].is_partner,
      partner_info: data.results[i].partner_info,

      bike_owner_id: bike_owner_id,
      bike_owner_avatar: userPhotoURL(data.results[i].owner.avatar.url),
      bike_owner_avatar_id: userPhotoID(
        data.results[i].owner.avatar.cloudinary_public_id
      ),
      bike_distance: findBikeDistanceScore(
        data.results[i].id,
        data.results_score
      ),
      bike_location: {
        latitude: search_latitude,
        longitude: search_longitude,
      },
    }

    cleanMapboxSearchData.allCoordinates.push([
      search_longitude, search_latitude
    ])

    if (cleanMapboxSearchData.groupedByOwner[bike_owner_id]) {
      cleanMapboxSearchData.groupedByOwner[bike_owner_id].push(tempBikeInfo)
    } else {
      cleanMapboxSearchData.groupedByOwner[bike_owner_id] = [tempBikeInfo]
    }
  }

  const { groupedByOwner } = cleanMapboxSearchData

  for (let key in groupedByOwner) {
    cleanMapboxSearchData.groupedMarkers.push(groupedByOwner[key])
  }

  cleanMapboxSearchData.resultsMeta = {
    total_rating_count: data.results_meta.total_rating_count,
    average_rating: data.results_meta.average_rating,
  }

  return cleanMapboxSearchData
}
