export const BIRTH_MONTH_OPTIONS = [
    { key: '01', value: '01', text: 'January' }, 
    { key: '02', value: '02', text: 'February' },
    { key: '03', value: '03', text: 'March' },
    { key: '04', value: '04', text: 'April' },
    { key: '05', value: '05', text: 'May' },
    { key: '06', value: '06', text: 'June' },
    { key: '07', value: '07', text: 'July' },
    { key: '08', value: '08', text: 'August' },
    { key: '09', value: '09', text: 'September' },
    { key: '10', value: '10', text: 'October' },
    { key: '11', value: '11', text: 'November' },
    { key: '12', value: '12', text: 'December' },
]