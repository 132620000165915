import {
  motoPhotoID,
  SEARCH_IMAGE_PARAMS
} from "../../../constants/images/sizesAndQuality"
import { CLOUDINARY_NAME } from "constants/images/cloudinary"
import cloudinary from "cloudinary-core"
import { buildBikeURL } from "components/utils"
const cl = new cloudinary.Cloudinary({
  cloud_name: CLOUDINARY_NAME
})

const motorcycleImageURL = motoID =>
  cl.url(motoPhotoID(motoID), SEARCH_IMAGE_PARAMS)


const formatListings = data => {
  let formattedArray = []

  // eslint-disable-next-line
  data.map((m, i) => {
    formattedArray.push({
      bike_id: m.uuid,
      bike_pretty_id: m.pretty_id,
      bike_title: m.make + " " + m.model,
      bike_make: m.make,
      bike_model: m.model,
      bike_year: m.year,
      bike_url: buildBikeURL({
        country: m.location.country_abbreviation,
        region: m.location.region_abbreviation,
        city: m.location.city,
        year: m.year.toString(),
        make: m.make,
        model: m.model,
        bikeID: m.pretty_id
      }),
      bike_edit_url: "/dashboard/garage/edit/" + m.uuid,
      bike_image: motorcycleImageURL(m.image.cloudinary_public_id),
      bike_image_cloudinary_public_id: m.image.cloudinary_public_id,
      bike_nickname: m.nickname,
      bike_state: m.state,
      location: {
        city: m.location.city,
        region: m.location.region,
        region_abbreviation: m.location.region_abbreviation,
        country: m.location.country,
        country_abbreviation: m.location.country_abbreviation
      },
      bike_vin: m.vin,
    })
  })

  return formattedArray
}

export const mygarageAPITransformer = data => {
  const bikesSize = findLoadMore(data)
  let formattedMyGarage = {
    active_Bikes: [
      ...formatListings(data.complete_listings),
      ...formatListings(data.pending_listings)
    ],
    needs_Attention_Bikes: formatListings(data.incomplete_listings),
    inactive_Bikes: [
      ...formatListings(data.rejected_listings),
      ...formatListings(data.inactive_listings)
    ],
    hasMore_Active_Bikes: bikesSize?.active_Bikes_Load,
    hasMore_Needs_Attention_Bikes: bikesSize?.needs_Attention_Bikes_Load,
    hasMore_Inactive_Bikes: bikesSize?.inactive_Bikes_Load,
  }

  return formattedMyGarage
}

const findLoadMore = data => {
  return {
    active_Bikes_Load: (data.complete_listings?.length < 20 || data.complete_listings?.length === 0)
      && (data.pending_listings?.length < 20 || data.pending_listings?.length === 0) ? true : false,
    needs_Attention_Bikes_Load: (data.incomplete_listings?.length < 20 || data.incomplete_listings?.length === 0) ? true : false,
    inactive_Bikes_Load: (data.rejected_listings?.length < 20 || data.rejected_listings?.length === 0)
      && (data.rejected_listings?.length < 20 || data.rejected_listings?.length === 0) ? true : false,
  }
}
