import moment from 'moment-timezone/builds/moment-timezone-with-data';

const yearOptions = () => {
  let yearOptionsArray = [];

  for (let i = 0; i < 25; i++) {
    yearOptionsArray.push({
      key: moment()
        .add(i, "years")
        .format("YYYY")
        .toString(),
      value: moment()
        .add(i, "years")
        .format("YYYY")
        .toString(),
      text: moment()
        .add(i, "years")
        .format("YYYY")
        .toString(),
    });
  }

  return yearOptionsArray;
}

export const YEAR_OPTIONS = yearOptions()
