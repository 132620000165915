import moment from 'moment'

export const parseDateRanges = (dateRanges) => {
  var returnDates = []

  for (let i in dateRanges) {
    const startDate = moment(dateRanges[i][0])
    const endDate = moment(dateRanges[i][1])

    while (startDate.isSameOrBefore(endDate)) {
      // Push a copy of lowerDate as it gets updated below
      returnDates.push(startDate.format('YYYY-MM-DD'));
      startDate.add(1, 'day');
    }
  }

  // Return a unique set of dates
  return returnDates.sort()
}
