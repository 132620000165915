import React from 'react'
import { isEmpty } from '../../utils'
import NumberFormat from 'react-number-format'
import { convertCents } from 'components/utils/convertCents'
import getSymbolFromCurrency from 'currency-symbol-map'
import {
  USER_SMALL_PARAMS,
  motoPhotoID,
  userPhotoID,
  SEARCH_IMAGE_PARAMS,
} from 'constants/images/sizesAndQuality'
import { CLOUDINARY_NAME } from 'constants/images/cloudinary'
import cloudinary from 'cloudinary-core'

const cl = new cloudinary.Cloudinary({
  cloud_name: CLOUDINARY_NAME,
})

const riderAvatarURL = (avatarID) =>
  cl.url(userPhotoID(avatarID), USER_SMALL_PARAMS)

const motorcycleImageURL = (motoID) =>
  cl.url(motoPhotoID(motoID), SEARCH_IMAGE_PARAMS)

export const reservationsTransformer = (data) => {
  const transformedReservations = data.results.map((reservation) => ({
    rider_name: reservation.rider.name,
    rider_state: reservation.rider.state,
    rider_avatar: riderAvatarURL(reservation.rider.avatar.cloudinary_public_id),
    reservation_actions: reservation.reservation.reservation_actions,
    moto_id: reservation.motorcycle.id,
    moto_title: `${reservation.motorcycle.year} ${reservation.motorcycle.make} ${reservation.motorcycle.model}`,
    moto_make: reservation.motorcycle.make,
    moto_model: reservation.motorcycle.model,
    moto_year: reservation.motorcycle.year,
    moto_image: reservation.motorcycle.images
      ? motorcycleImageURL(
        reservation.motorcycle.images[0].cloudinary_public_id
      )
      : null,
    moto_vin: reservation.motorcycle.vin,
    reservation_id: reservation.reservation.uuid,
    reservation_total_cents:
      reservation.request.financial?.owner_revenue?.owner_revenue_cents,
    reservation_total_currency:
      reservation.request.financial?.owner_revenue?.owner_revenue_currency,
    reservation_total: (
      <FormatPrice
        cents={
          reservation.request.financial?.owner_revenue?.owner_revenue_cents
        }
        currency={
          reservation.request.financial?.owner_revenue?.owner_revenue_currency
        }
      />
    ),
    reservation_state: reservation.reservation.state,
    reservation_start: reservation.request.start_at,
    reservation_end: reservation.request.end_at,
    reservation_created: reservation.request.created_at,
    reservation_timezone: reservation.owner.is_partner ?
      reservation.owner.partner_info.timezone :
      reservation.reservation
        .timezone_of_bike_at_time_booking
        ? reservation.reservation.timezone_of_bike_at_time_booking
        : 'America/Chicago',
    rider_message: isEmpty(reservation.request.message_request)
      ? ''
      : reservation.request.message_request,
    reservation_url: `/dashboard/reservations/${reservation.reservation.uuid}`,
    chat_session_id: reservation.chat_session.id,
    unread_messages_count: reservation.chat_session.unread_messages_count,
    bike_delivery_fees_information:
    reservation.pricing.request_details.delivery_fees_information
  }))

  return { results: transformedReservations, results_meta: data.results_meta }
}

const FormatPrice = ({ cents, currency }) => (
  <NumberFormat
    value={convertCents(cents)}
    displayType="text"
    decimalScale={0}
    thousandSeparator={true}
    prefix={getSymbolFromCurrency(currency)}
  />
)
