import { WORLD_COUNTRIES } from './../../constants';
export const fullCountryFromShortCode = (countryShortCode) => {
    let countryFullName = ''
    for(let i=0; i<WORLD_COUNTRIES.length; i++) {
      if(WORLD_COUNTRIES[i].shortCode === countryShortCode) {
  
          countryFullName = WORLD_COUNTRIES[i].text    
          break
      }
    }
    return countryFullName 
  }