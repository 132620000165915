export const paymentTypeInfo = (cardType) => {
  cardType = cardType.toLowerCase();

  let cardIconArray = {
    visa: "/static/images/paymentIcons/visa.png",
    mastercard: "/static/images/paymentIcons/mastercard.png",
    diners: "/static/images/paymentIcons/diners.png",
    jcb: "/static/images/paymentIcons/jcb.png",
    americanexpress: "/static/images/paymentIcons/americanexpress.png",
    discover: "/static/images/paymentIcons/discover.png",
  };

  if (cardIconArray.hasOwnProperty(cardType)) {
    return cardIconArray[cardType];
  } else {
    return "/static/images/paymentIcons/credit-card.png";
  }
};
