import { lazyWithRetry } from 'components/lazyUtil.js'

const PaymentPage = lazyWithRetry(() => import('../../payments/booking/bookPayment.js'))

const routes = [
  {
    path: '/payments/book/:reservationID',
    component: PaymentPage,
    private: true,
  },
]

export default routes
