import {
  motoPhotoID,
  motoPhotoURL,
  userPhotoID,
  userPhotoURL,
} from '../../../constants/images/sizesAndQuality'
import { find_price_N_cents } from './find_price_N_cents'
import { buildBikeURL } from '../../utils'

export const mapboxBikeSearchAPIConnector = async (data) => {
  let cleanSearchData = {
    results: [],
  }

  //setting up variables for multiday hack
  let dailyRentalPrice = 0
  let multiday5 = 0
  let multiday10 = 0
  let multiday15 = 0
  let multidayN = 0

  for (let i = 0; i < data.results.length; i++) {
    dailyRentalPrice = data.results[i].daily_rental_price_cents
    if (Object.keys(data.results[i]?.pricing?.multi_day_pricing).length > 0) {
      multidayN = await find_price_N_cents(dailyRentalPrice, data.results[i]?.pricing?.multi_day_pricing)
    }
    multiday5 = dailyRentalPrice - dailyRentalPrice * (5 / 100)
    multiday10 = dailyRentalPrice - dailyRentalPrice * (10 / 100)
    multiday15 = dailyRentalPrice - dailyRentalPrice * (15 / 100)

    cleanSearchData.results.push({
      //multidayHackData
      day_price_cents: dailyRentalPrice,
      day_price_currency: data.results[i].daily_rental_price_currency,

      day_price_N_cents: multidayN,
      day_price_5_cents: multiday5,
      day_price_10_cents: multiday10,
      day_price_15_cents: multiday15,

      has_multiday: data.results[i].pricing.multi_day_discounts,
      multi_day_pricing: data.results[i].pricing.multi_day_pricing,
      //Previous Data
      bike_id: data.results[i].pretty_id,
      bike_year: data.results[i].year,
      bike_model: data.results[i].model,
      bike_make: data.results[i].make,
      bike_owner_name: data.results[i].is_partner
        ? data.results[i].partner_info.partner_name
        : data.results[i].owner.first_name +
        ' ' +
        data.results[i].owner.last_name_initial,
      bike_owner_id: data.results[i].owner.pretty_id,
      bike_images: data.results[i].images,
      bike_image_url: motoPhotoURL(data.results[i].images[0].url),
      bike_image_cloudinary_public_id: motoPhotoID(
        data.results[i].images[0].cloudinary_public_id
      ),
      bike_location: {
        region: data.results[i].location.region,
        region_abbreviation: data.results[i].location.region_abbreviation,
        city: data.results[i].location.city,
        country: data.results[i].location.country,
        country_abbreviation: data.results[i].location.country_abbreviation,
        latitude: data.results[i].location.search_latitude,
        longitude: data.results[i].location.search_longitude,
        radius: data.results[i].location.search_radius_meters,
      },
      bike_distance: 0,
      ratings_count: data.results[i].reviews.ratings_count, //Number like '4'
      average_rating:
        Math.round(data.results[i].reviews.ratings_average * 10) / 10, //Number
      bike_owner_avatar: userPhotoURL(data.results[i].owner.avatar.url),
      bike_owner_avatar_id: userPhotoID(
        data.results[i].owner.avatar.cloudinary_public_id
      ),
      bike_owner_first_name: data.results[i].is_partner
        ? data.results[i].partner_info.partner_name
        : data.results[i].owner.first_name,
      bike_category: data.results[i].category,
      bike_created: data.results[i].created_at,
      is_newly_listed: data.results[i].is_newly_listed,
      can_book_instantly: data.results[i].can_book_instantly,
      is_partner: data.results[i].is_partner,
      in_wishlist: data.results[i].in_wishlist,
      bike_uuid_id: data.results[i].uuid,
      bike_url: buildBikeURL({
        country: data.results[i].location.country_abbreviation,
        region: data.results[i].location.region_abbreviation,
        city: data.results[i].location.city,
        year: data.results[i].year.toString(),
        make: data.results[i].make,
        model: data.results[i].model,
        bikeID: data.results[i].pretty_id,
      }),
      partner_inventory_count:
        data.results[i].partner_info.partner_inventory_count,
      partner_type: data.results[i].partner_info.partner_type,
      total_trips_taken: data.results[i].trips_taken,
    })
  }

  return cleanSearchData
}
