import React, { useState, useRef } from 'react'
import { Modal, Button, Columns } from "components/elements"
import MagicSliderDots from "react-magic-slider-dots"
import Slider from "react-slick"
import ReserveBikeIcon from "./images/reserve-bike.png"
import ReserveBikeInactiveIcon from "./images/reserve-bike-inactive.png"
import RideBikeIcon from "./images/ride-bike.png"
import RideBikeInactiveIcon from "./images/ride-bike-inactive.png"
import BuyBikeIcon from "./images/buy-bike.png"
import BuyBikeInactiveIcon from "./images/buy-bike-inactive.png"
import RideBikeSlider from "./images/slider-2.png"
import BuyBikeSlider from "./images/slider-3.png"
import ReserveBikeSlider from "./images/slider-1.png"
import "./style.sass"

const steps = [
  {
    className: "mt-5 is-vcentered",
    activeImage: ReserveBikeIcon,
    inActiveImage: ReserveBikeInactiveIcon,
    alt: "Reserve the bike",
    title: "Reserve the bike",
    subtitle: "Pay for your refundable demo",
    buttonLabel: "Next"
  },
  {
    className: "is-vcentered",
    activeImage: RideBikeIcon,
    inActiveImage: RideBikeInactiveIcon,
    alt: "Ride the bike",
    title: "Ride the bike",
    subtitle: "Have fun and see how it rides",
    buttonLabel: "Next"
  },
  {
    className: "is-vcentered",
    activeImage: BuyBikeIcon,
    inActiveImage: BuyBikeInactiveIcon,
    alt: "Buy the bike",
    title: "Buy the bike",
    subtitle: "The dealer will apply these costs to the price of the bike",
    buttonLabel: "Let's Begin!"
  }
]

const TryMeModal = ({ modalOpen, onClose, isMobile }) => {
  const sliderRef = useRef(null)
  const [activeStep, setActiveStep] = useState(0)
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />
    },
    beforeChange: (currentSlide, nextSlide) => {
      setActiveStep(nextSlide)
    }
  };
  const handleClose = () => {
    onClose()
  }
  const handleSlider = () => {
    if (activeStep === 2) {
      handleClose()
    } else {
      sliderRef.current.slickNext()
    }
  }

  return (
    <Modal
      show={modalOpen}
      showClose={false}
      onClose={handleClose}
      closeOnBlur={true}
      closeOnEsc={true}
      size="medium"
      boxStyle={{
        borderRadius: 24
      }}
      mobileHeight={'auto'}
    >
      <Columns className={`try-my-modal is-vcentered bg-grey ${isMobile ? 'has-text-centered' : ''}`}>
        <Columns.Column className={`${isMobile ? 'p-0' : 'has-background-white'}`}>
          {
            isMobile && (
              <div className='mobile-tryme-slider pb-2'>
                <Slider ref={sliderRef} {...sliderSettings}>
                  <img src={ReserveBikeSlider} style={{width: 75}} alt="reserve-bike"/>
                  <img src={RideBikeSlider} style={{width: 75}} alt="ride-bike"/>
                  <img src={BuyBikeSlider} style={{width: 75}} alt="buy-bike"/>
                </Slider>
              </div>
            )
          }
          <div className="has-background-white">
            <div className={`step-title has-text-weight-bold has-text-black ${isMobile ? 'has-text-centered pt-2' : 'is-size-4'}`}>24-hour refundable test ride</div>
            { !isMobile && steps.map((step, index) => {
              return (
                <Columns className={step.className} key={index}>
                  <Columns.Column size={3}>
                    <img
                      src={index === activeStep ? step.activeImage : step.inActiveImage}
                      alt={step.alt}
                    />
                  </Columns.Column>
                  <Columns.Column size={9}>
                    <div className="has-text-black has-text-weight-bold is-size-5">{step.title}</div>
                    <div className="is-size-7 has-text-black">{step.subtitle}</div>
                  </Columns.Column>
                </Columns>
              )
            })}
            {
              isMobile && (
                <>
                  <div className="my-1">
                    <img
                      className='try-me-icon'
                      src={steps[activeStep].activeImage}
                      alt={steps[activeStep].alt}
                    />
                  </div>
                  <div className="my-1">
                    <div className="has-text-black has-text-weight-bold step-title">{steps[activeStep].title}</div>
                    <div className="is-size-7 has-text-black step-subtitle">{steps[activeStep].subtitle}</div>
                  </div>
                </>
              )
            }
            <div className="has-text-centered pb-2">
              <Button
                className={`mt-1 ${isMobile ? 'step-action' : ''}`}
                color="primary"
                onClick={handleSlider}
              >
                {steps[activeStep].buttonLabel}
              </Button>
            </div>
          </div>
        </Columns.Column>
        {
          !isMobile && (
            <Columns.Column className="slider-container" size={5}>
              <div>
                <Slider ref={sliderRef} {...sliderSettings}>
                  <img src={ReserveBikeSlider} alt="reserve-bike"/>
                  <img src={RideBikeSlider} alt="ride-bike"/>
                  <img src={BuyBikeSlider} alt="buy-bike"/>
                </Slider>
              </div>
            </Columns.Column>
          )
        }
      </Columns>
    </Modal>
  )
}

export default TryMeModal