export const MOTORCYCLE_EVENT_TYPES = [
  { key: 0, value: "Benefit - Charity", text: "Benefit - Charity" },
  { key: 1, value: "Bike Night", text: "Bike Night"},
  { key: 2, value: "General Ride", text: "General Ride"},
  { key: 3, value: "Motorcycle Race", text: "Motorcycle Race"},
  { key: 4, value: "Motorcycle Rally", text: "Motorcycle Rally"},
  { key: 5, value: "Motorcycle Show", text: "Motorcycle Show"},
  { key: 6, value: "Party", text: "Party"},
  { key: 7, value: "Poker Run", text: "Poker Run"},
  { key: 8, value: "Swap Meet", text: "Swap Meet"},
  { key: 9, value: "Other", text: "Other"},
]

export const MOTORCYCLE_EVENT_TYPES_MASTER_LIST = MOTORCYCLE_EVENT_TYPES.map(event => event.value)