import React, { useEffect } from 'react'
import QueryString from 'query-string'
import routes from './routeInfo'
import { Route, Redirect, useLocation, Switch } from 'react-router-dom'
import { setFeatureFlag } from '../utils/featureFlag'

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      rest.isAuth === true ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
)

export const BuildRoutes = ({ isMobile, isAuth, auth }) => {
  const location = useLocation()

  useEffect(() => {
    const queryParams = QueryString.parse(location.search)
    setFeatureFlag(queryParams)
  }, [location])

  return (
    <React.Fragment>
      <Switch>
        {routes.map(
          ({ path, component: Component, is_private, is_mother }, key) =>
            !is_private ? (
              <Route
                exact={is_mother ? false : true}
                path={path}
                key={key}
                render={(props) => (
                  <Component
                    {...props}
                    isMobile={isMobile}
                    isAuth={isAuth}
                    auth={auth}
                  />
                )}
              />
            ) : (
              <PrivateRoute
                isAuth={isAuth}
                isMobile={isMobile}
                auth={auth}
                exact={is_mother ? false : true}
                path={path}
                key={key}
                component={Component}
              />
            )
        )}
      </Switch>
    </React.Fragment>
  )
}

export default BuildRoutes
