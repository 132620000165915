import { apiErrorHandling } from "../apiErrorHandling";

export const apiPOST = (data, config, url, processName, componentDesc, axios) => {

    return axios.post(url, data, config).then(function(response) {
        
        return response.data;
    })
    .catch(function(error) {
        throw apiErrorHandling(
            "POST", 
            error, 
            data, 
            componentDesc,
            processName, 
            url
    )
    })

}



