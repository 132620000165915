//Basic Step Key
export const BASIC_STEP = "basic-info"
export const BASIC_STEP_DESCRIPTION = "Motorcycle Basic Info"

//Fields
export const MAKE = "make"
export const YEAR = "year"
export const MODEL = "model"
export const LICENSE_PLATE = "license_plate"
export const ISSUING_STATE = 'issuing_state'
export const ISSUING_COUNTRY = 'issuing_country'
export const ODOMETER = "odometer"
export const CATEGORY = "category"
export const INVENTORY_ID = "inventory_id"
export const PARKING_TYPE = "parking_type"