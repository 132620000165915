import { lazyWithRetry } from 'components/lazyUtil.js'

const Referrals = lazyWithRetry(() =>
  import('../../referrals/invite/referralsInvite.js')
)
const ReferralsInvite = lazyWithRetry(() =>
  import('../../referrals/accept/referralsLanding.js')
)
const HomePage = lazyWithRetry(() =>
  import('../../marketing/homepage/homepage.js')
)
const HowItWorks = lazyWithRetry(() =>
  import('../../marketing/howItWorks/howItWorks.js')
)
const FAQ = lazyWithRetry(() => import('../../marketing/faq/faq.js'))
const RedirectFaqToSupport = lazyWithRetry(() => import('../../marketing/faq/redirectFaqToSupport.js'))
const About = lazyWithRetry(() => import('../../marketing/about/about.js'))
const Contact = lazyWithRetry(() =>
  import('../../marketing/contact/contact.js')
)
const Terms = lazyWithRetry(() => import('../../marketing/terms/terms.js'))
const ExtendedTestRideTerms = lazyWithRetry(() =>
  import('../../marketing/extendedTestRideTerms/extendedTestRideTerms.js')
)
const Privacy = lazyWithRetry(() =>
  import('../../marketing/privacy/privacy.js')
)
const OtherPolicies = lazyWithRetry(() =>
  import('../../marketing/otherPolicies/otherPolicies.js')
)
const Insurance = lazyWithRetry(() =>
  import('../../marketing/insurance/insurance.js')
)
const Locations = lazyWithRetry(() => import('../../locations/locations.js'))
const ReviewFeed = lazyWithRetry(() =>
  import('../../marketing/reviewFeeds/reviewsFeed.js')
)
const ForemostInsurance = lazyWithRetry(() =>
  import('../../marketing/foremostInsurance/foremost.js')
)
const Listings = lazyWithRetry(() => import('../../listings/listings.js'))
const ListBikeLandingPage = lazyWithRetry(() =>
  import('../../marketing/listMotorcycle/listBikeLandingPage.js')
)
const OneTwoFree = lazyWithRetry(() =>
  import('../../marketing/landingPages/oneTwoFree.js')
)
const RidersPreferred = lazyWithRetry(() =>
  import('components/marketing/landingPages/ridersPlus/ridersPreferred.js')
)
const TryMe = lazyWithRetry(() => import('components/marketing/tryMe/tryMe.js'))

const routes = [
  {
    path: '/',
    component: HomePage,
    is_private: false,
  },
  {
    path: '/faq',
    component: RedirectFaqToSupport,
    is_private: false,
  },
  {
    path: '/about-us',
    component: About,
    is_private: false,
  },
  {
    path: '/contact-us',
    component: Contact,
    is_private: false,
  },
  {
    path: '/terms-conditions',
    component: Terms,
    is_private: false,
  },
  {
    path: '/extended-test-ride-terms-of-service',
    component: ExtendedTestRideTerms,
    is_private: false,
  },
  {
    path: '/privacy-policy',
    component: Privacy,
    is_private: false,
  },
  {
    path: '/other-policies',
    component: OtherPolicies,
    is_private: false,
  },
  {
    path: '/how-it-works',
    component: HowItWorks,
    is_private: false,
  },
  {
    path: '/how-it-works/insurance',
    component: Insurance,
    is_private: false,
  },
  {
    path: '/locations/:locationName',
    component: Locations,
    is_private: false,
  },
  {
    path: '/reviews',
    component: ReviewFeed,
    is_private: false,
  },
  {
    path: '/foremost-insurance',
    component: ForemostInsurance,
    is_private: false,
  },
  {
    path: '/list-your-motorcycle',
    component: ListBikeLandingPage,
    is_private: false,
  },
  {
    path: '/referrals/invite',
    component: Referrals,
    is_private: false,
  },
  {
    path: '/referrals/redeem',
    component: ReferralsInvite,
    is_private: false,
  },
  {
    path: '/ref/:refID',
    component: ReferralsInvite,
    is_private: false,
  },
  {
    path: '/listings/:region/:city/:make/:category',
    component: Listings,
    is_private: false,
  },
  {
    path: '/listings/:region/:city/:make',
    component: Listings,
    is_private: false,
  },
  {
    path: '/listings/:region/:city',
    component: Listings,
    is_private: false,
  },
  {
    path: '/one-two-free',
    component: OneTwoFree,
    is_private: false,
  },
  {
    path: '/riders-preferred-membership',
    component: RidersPreferred,
    is_private: false,
  },
  {
    path: '/listings/:region',
    component: Listings,
    is_private: false,
  },
  {
    path: '/try_me',
    component: TryMe,
    is_private: false,
  },
]

export default routes
