export const isEmpty = (value) => {
  if (value === undefined) {
    return true
  } else if (value === null) {
    return true
  } else if (value.trim() === '') {
    return true
  } else {
    return false
  }
}
