import { apiErrorHandling } from "../apiErrorHandling"

export const apiPUT = (data, config, url, processName, componentDesc, axios) => {
    return axios.put(url, data, config)
      .then(function(response) {
        return response.data
      })
      .catch(function(error) {
        throw apiErrorHandling(
            "PUT", 
            error, 
            data, 
            componentDesc,
            processName, 
            url
        )
      })
}