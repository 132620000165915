import { motorcycleEventsTransformer, motorcycleEventsSearchTransformer } from '../transformers/motorcycleEventsListTransformer.js'

export const MOTORCYCLE_EVENTS_CALLS = {
  'create-motorcycle-event': {
    use_mock: false,
    mock_endpoint: 'motorcycle_events',
    api_type: 'POST',
    requires_auth: true,
  },
  'motorcycle-events-list': {
    use_mock: false,
    mock_endpoint: 'motorcycle_events',
    api_type: 'GET',
    requires_auth: false,
  },
  'get-featured-events-list': {
    use_mock: false,
    mock_endpoint: 'motorcycle_events',
    api_type: 'GET',
    requires_auth: false,
  },
  'search-motorcycle-events': {
    use_mock: false,
    mock_endpoint: 'motorcycle_events',
    api_type: 'GET',
    requires_auth: false,
    transFormData: (data) => {
      return motorcycleEventsSearchTransformer(data)
    },
  },
  'get-motorcycle-event': {
    use_mock: false,
    mock_endpoint: 'motorcycle_events',
    api_type: 'GET',
    requires_auth: false,
    transFormData: (data) => {
      return motorcycleEventsTransformer(data)
    },
  },
}
