import { 
  US_STATES,
  CANADA_PROVINCES
  } from './../../constants';

export const fullStateFromShortCode = (countryShortCode, stateShortCode) => {
  
    let stateFullName = stateShortCode
  
    //if it's the US
    if(countryShortCode === 'US' || 'USA') {
  
      for(let i=0; i<US_STATES.length; i++) {
        if(US_STATES[i].value === stateShortCode) {
            stateFullName = US_STATES[i].text    
            break
        }
      }
    
    }
  
    //if it's Canada
    if(countryShortCode === 'CA') {
  
      for(let i=0; i<CANADA_PROVINCES.length; i++) {
        if(CANADA_PROVINCES[i].value === stateShortCode) {
            stateFullName = CANADA_PROVINCES[i].text    
            break
        }
      }
    
    }
    return stateFullName 
}