import moment from 'moment-timezone/builds/moment-timezone-with-data'

const buildYearOptionsListing = () => {
  let yearOptionsListing = []

  //we add 3 years in the future
  yearOptionsListing.push({
    key: moment()
      .add(3, 'years')
      .format('YYYY')
      .toString(),
    value: moment()
      .add(3, 'years')
      .format('YYYY')
      .toString(),
    text: moment()
      .add(3, 'years')
      .format('YYYY')
      .toString(),
  })

  yearOptionsListing.push({
    key: moment()
      .add(2, 'years')
      .format('YYYY')
      .toString(),
    value: moment()
      .add(2, 'years')
      .format('YYYY')
      .toString(),
    text: moment()
      .add(2, 'years')
      .format('YYYY')
      .toString(),
  })

  yearOptionsListing.push({
    key: moment()
      .add(1, 'years')
      .format('YYYY')
      .toString(),
    value: moment()
      .add(1, 'years')
      .format('YYYY')
      .toString(),
    text: moment()
      .add(1, 'years')
      .format('YYYY')
      .toString(),
  })

  let thisYear = moment().format('YYYY')

  //alert(thisYear - 1920)

  let yearCount = thisYear - 1919

  for (let i = 0; i < yearCount; i++) {
    yearOptionsListing.push({
      key: moment()
        .subtract(i, 'years')
        .format('YYYY')
        .toString(),
      value: moment()
        .subtract(i, 'years')
        .format('YYYY')
        .toString(),
      text: moment()
        .subtract(i, 'years')
        .format('YYYY')
        .toString(),
    })
  }

  return yearOptionsListing
}

export const LISTING_OPTIONS_YEARS = buildYearOptionsListing()
