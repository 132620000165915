import { lazyWithRetry } from 'components/lazyUtil.js'
const MotorcycleBrands = lazyWithRetry(() => import('../../brands/motorcyclesBrands'))

const routes = [
  {
    path: '/brand/:brandName',
    component: MotorcycleBrands,
    private: false,
  },
]

export default routes
