import React from 'react'
import { Select as BulmaSelect } from 'react-bulma-components/lib/components/form'
import classNames from 'classnames'
import './select.sass'

const TwistedSelect = ({ enhanced, onChange, options, className, ...rest }) => {
  let selectClass = classNames('twisted-select', className, {
    'select-full-width': rest.fullwidth,
    nothing: rest.value,
  })

  if (enhanced) {
    return null // we don't have this yet.
  }

  if (!enhanced) {
    return (
      <BulmaSelect
        {...rest}
        onChange={(e) => onChange(e)}
        className={selectClass}
      >
        <option disabled selected hidden value="">
          {rest.placeholder}
        </option>
        {options.map((option, i) => (
          <option key={i} value={option.value}>
            {option.text}
          </option>
        ))}
      </BulmaSelect>
    )
  }
}

export default TwistedSelect
