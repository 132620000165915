import { apiProcess } from '../../components/api/api'

//KEYS
export const CLOUDINARY_NAME = process.env.REACT_APP_CLOUDINARY_NAME
export const CLOUDINARY_API_KEY = process.env.REACT_APP_CLOUDINARY_API_KEY

//PRESET...WE SHOULD REALLY MAKE AN OBJECT FOR THESE AND PASS IN THE ENVIRONMENT FOR EASIER MANAGEMENT ON THE SERVERS
export const CLOUDINARY_VEHICLE_PRESET =
  process.env.REACT_APP_CLOUDINARY_VEHICLE_PRESET
export const CLOUDINARY_TRIP_PRESET =
  process.env.REACT_APP_CLOUDINARY_TRIP_PRESET
export const CLOUDINARY_USER_PRESET =
  process.env.REACT_APP_CLOUDINARY_USER_PRESET
export const REACT_APP_CLOUDINARY_USER_PRESET_REVIEWS =
  process.env.REACT_APP_CLOUDINARY_USER_PRESET_REVIEWS
export const CLOUDINARY_DOCUMENTS_PRESET =
  process.env.REACT_APP_CLOUDINARY_DOCUMENTS_PRESET
export const CLOUDINARY_OWNER_START_TRIPS_PRESET =
  process.env.REACT_APP_CLOUDINARY_TRIPS_OWNER_START_PRESET
export const CLOUDINARY_OWNER_END_TRIPS_PRESET =
  process.env.REACT_APP_CLOUDINARY_TRIPS_OWNER_END_PRESET
export const CLOUDINARY_VEHICLE_INSPECTIONS_PRESET =
  process.env.REACT_APP_CLOUDINARY_VEHICLE_INSPECTIONS_PRESET

//BASE STYLES FOR IF WE USE THE WIDGET
export const CLOUDINARY_WIDGET_STYLES = {
  palette: {
    window: '#ffffff',
    sourceBg: '#f4f4f5',
    windowBorder: '#90a0b3',
    tabIcon: '#000000',
    inactiveTabIcon: '#555a5f',
    menuIcons: '#555a5f',
    link: '#B9995A',
    action: '#339933',
    inProgress: '#0433ff',
    complete: '#339933',
    error: '#cc0000',
    textDark: '#000000',
    textLight: '#fcfffd',
  },
  fonts: {
    default: null,
    'sans-serif': {
      url: null,
      active: true,
    },
  },
}

export const makeCloudinarySignature = (callBack, paramsToSign) => {
  let apiURL = 'media/images/secure_upload_token'
  let sigData = {
    params_to_sign: paramsToSign,
  }
  apiProcess(
    apiURL,
    sigData,
    'cloudinary-signature',
    'Generate Cloudinary Upload Sig from Start Trip Upload'
  )
    .then(function(responseData) {
      callBack(responseData.cloudinary_signed_token)
    })
    .catch(function(responseData) {})
}
