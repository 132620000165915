const provinces = require('provinces')

const usStates = () => {
  let statesProvinces = [];

  for (var i = 0; i < provinces.length; i++) {
    if (provinces[i].country === "US") {
      statesProvinces.push({
        key: provinces[i].short,
        value: provinces[i].short,
        text: provinces[i].name,
      });
    }
  }

  return statesProvinces;
}

export const US_STATES = usStates();
