import moment from 'moment'

export const formatEventDate = (start_date, end_date, timezone) => {
  const startDateObj = moment(start_date).tz(timezone)
  const endDateObj = moment(end_date).tz(timezone)
  if (startDateObj && endDateObj) {
    if (start_date === end_date) {
      return startDateObj.format('ddd, DD MMM YYYY hh:mm A z')
    }
    if (startDateObj.isSame(endDateObj, 'day')) {
      return `${startDateObj.format(
        'ddd, DD MMM YYYY hh:mm A'
      )} - ${endDateObj.format('hh:mm A z')}`
    }
    return `${startDateObj.format(
      'ddd, DD MMM YYYY hh:mm A'
    )} - ${endDateObj.format('ddd, DD MMM YYYY hh:mm A z')}`
  }
}

export const formatShortenedEventDate = (start_date, end_date, timezone) => {
  const startDateObj = moment(start_date).tz(timezone)
  const endDateObj = moment(end_date).tz(timezone)
  if (startDateObj && endDateObj) {
    if (start_date === end_date) {
      return startDateObj.format('M/DD/YY ha z')
    }
    if (startDateObj.isSame(endDateObj, 'day')) {
      return `${startDateObj.format(
        'M/DD/YY ha'
      )} — ${endDateObj.format('ha z')}`
    }
    return `${startDateObj.format(
      'M/DD/YY ha'
    )} — ${endDateObj.format('M/DD/YY ha z')}`
  }
}
