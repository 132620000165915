import { userPhotoURL } from '../../../constants/images/sizesAndQuality'
import moment from 'moment'

export const recentReviewsTransformers = (data) => {
  let formattedRatingsData = []

  for (let i = 0; i < data.length; i++ ) {
    const bikeShowPage = data[i].motorcycle.motorcycle_show_page
    const urlIndex = bikeShowPage.indexOf('/bikes/')
    const showPageUrl = bikeShowPage.substring(urlIndex)

    formattedRatingsData.push({
      rider: {
        avatar_url: userPhotoURL(data[i].rider.rider_avatar_image).replace(
          'image/upload/',
          'image/upload/c_fill,g_auto,h_75,w_75,q_auto,f_auto/'
        ),
        name: data[i].rider.rider_name,
      },
      motorcycle: {
        name: data[i].motorcycle.motorcycle_name,
        show_page: showPageUrl
      },
      rating: {
        description: data[i].rating.description,
        score: data[i].rating.score,
        created_at: moment(data[i].rating.created_at).format('MMMM D, YYYY')
      }
    })
  }

return formattedRatingsData

}
