import React, { Component } from 'react'
import Button from 'react-bulma-components/lib/components/button'
import { Control, Field } from 'react-bulma-components/lib/components/form'
import classNames from 'classnames'
import './button.css'
import PropTypes from 'prop-types'
import DotsLoaderDark from '../../theme/assets/images/loaders/three-dots-dark.svg'
import DotsLoaderLight from '../../theme/assets/images/loaders/three-dots-light.svg'

const ButtonWrap = ({ includeControl, children }) =>
  includeControl ? (
    <Field>
      <Control>{children}</Control>
    </Field>
  ) : (
    <>{children}</>
  )

class TwistedButton extends Component {
  render() {
    const {
      loading,
      loaderColor,
      iconLeft,
      iconRight,
      includeControl,
      size,
      ...rest
    } = this.props

    const loaderImage =
      loaderColor === 'light' ? DotsLoaderLight : DotsLoaderDark

    //need to do some icon work here. it needs a proper size.
    let iconClassName = classNames('icon', null, {
      'is-small': size === 'small',
      'is-normal': size === 'normal' || size === 'medium',
      'is-medium': size === 'large'
    })

    return (
      <ButtonWrap includeControl={includeControl}>
        <Button {...rest} size={size}>
          {!loading && (
            <>
              {iconLeft && (
                <span className={iconClassName}>
                  <i className={iconLeft}></i>
                </span>
              )}
              <span className="inner-container">{this.props.children}</span>
              {iconRight && (
                <span className={iconClassName}>
                  <i className={iconRight}></i>
                </span>
              )}
            </>
          )}
          {loading && (
            <img
              src={loaderImage}
              alt="Loading"
              style={{ width: '50px', height: 'auto' }}
            />
          )}
        </Button>
      </ButtonWrap>
    )
  }
}

//the majority of props will just be passed through to the main bulma button
TwistedButton.defaultProps = {
  loading: false,
  loaderColor: 'light',
  iconLeft: null,
  iconRight: null,
  includeControl: false
}

TwistedButton.propTypes = {
  loading: PropTypes.bool,
  includeControl: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  loaderColor: PropTypes.oneOf(['light', 'dark']) //tiny
}

export default TwistedButton
