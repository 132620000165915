import { makeTitleCase, isEmpty } from '../../utils'

import { reviewsFromRidersAPITransformer } from '../../bikes/calls/reviewFromRidersTransformer'

import { userPhotoURL, userPhotoID, motoPhotoID, motoPhotoURL } from '../../../constants/images/sizesAndQuality'

export const userProfileAPITransformer = (data) => {
  let getUserCleanData = {
    user_id: data.pretty_id,
    //"user_rating" : 4,
    user_rating_count: (data.reviews.received_from_riders_count || data.reviews.received_from_riders.length) + (data.reviews.received_from_owners_count || data.reviews.received_from_owners.length),
    user_location: data.location.city
      ? makeTitleCase(data.location.city) + ', ' + data.location.region_abbreviation
      : null,
    user_joined_date: data.joined_at,
    user_avatar: userPhotoURL(data.user_avatar.url),
    user_avatar_cloudinary_public_id: userPhotoID(data.user_avatar.cloudinary_public_id),
    user_first_name: data.first_name,
    user_email: data.email,
    user_phone: data.phone,
    user_complete_last_name: data.last_name,
    user_last_name: data.last_name_initial,
    owner_ssn: data.owner_ssn,
    //"user_is_owner" : true,
    user_bike_listings: [],
    user_description: isEmpty(data.rider.rider_resume) ? null : data.rider.rider_resume,
    user_guest_reviews: data.reviews.hasOwnProperty('received_from_riders')
      ? reviewsFromRidersAPITransformer(data.reviews.received_from_riders)
      : [],
    user_owner_reviews: [],
    verified_email: data.verified_email,
    verified_phone: data.verified_phone,
    verified_staff: data.verfied_staff,
    verified_id: data.verfied_id,
    referral_code: data.referral_code,
    user_full_name: data.first_name + ' ' + data.last_name_initial,
    is_partner: data.is_partner,
    user_location_info: data.location,
    time_zone: data.timezone
  }

  if (data.is_partner) {
    getUserCleanData['user_full_name'] = data.partner_info.partner_name
    getUserCleanData['partner_banner'] = data.partner_info.partner_banner
    getUserCleanData['partner_type'] = data.partner_info.partner_type
    getUserCleanData['partner_hours'] = data.partner_info.partner_working_hours
    getUserCleanData['partner_info'] = data.partner_info
  }

  //build bike listings
  for (let i = 0; i < data.owner.motorcycles.length; i++) {
    getUserCleanData.user_bike_listings.push({
      bike_id: data.owner.motorcycles[i].pretty_id,
      bike_image: motoPhotoURL(data.owner.motorcycles[i].image.url),
      bike_image_cloudinary_public_id: motoPhotoID(data.owner.motorcycles[i].image.cloudinary_public_id),
      bike_title:
        data.owner.motorcycles[i].year + ' ' + data.owner.motorcycles[i].make + ' ' + data.owner.motorcycles[i].model,
      bike_year: data.owner.motorcycles[i].year,
      bike_make: data.owner.motorcycles[i].make,
      bike_review_score: data.owner.motorcycles[i].reviews.ratings_average,
      bike_review_count: data.owner.motorcycles[i].reviews.ratings_count,
      bike_model: data.owner.motorcycles[i].model,
      location: data.owner.motorcycles[i].location,
      daily_rental_price_cents: data.owner.motorcycles[i].daily_rental_price_cents,
      daily_rental_price_currency: data.owner.motorcycles[i].daily_rental_price_currency,
    })
  }

  if (data.reviews.hasOwnProperty('received_from_owners')) {
    for (let i = 0; i < data.reviews.received_from_owners.length; i++) {
      getUserCleanData.user_owner_reviews.push({
        of_rider_submitted: data.reviews.received_from_owners[i].review.of_rider_submitted,
        owner_review_id: null,
        owner_review_name:
          data.reviews.received_from_owners[i].owner.first_name +
          ' ' +
          data.reviews.received_from_owners[i].owner.last_initial,
        owner_id: data.reviews.received_from_owners[i].owner.pretty_id,
        owner_review_date: data.reviews.received_from_owners[i].review.of_rider.created_at,
        owner_review_avatar: data.reviews.received_from_owners[i].owner.avatar.url,
        owner_review_avatar_cloudinary_public_id:
          data.reviews.received_from_owners[i].owner.avatar.cloudinary_public_id,
        owner_review_rating: data.reviews.received_from_owners[i].review.of_rider.score,
        owner_review_text: data.reviews.received_from_owners[i].review.of_rider.description,
        owner_review_bike_id: data.reviews.received_from_owners[i].motorcycle.pretty_id,
        owner_review_bike_make: data.reviews.received_from_owners[i].motorcycle.make,
        owner_review_bike_model: data.reviews.received_from_owners[i].motorcycle.model,
        owner_review_bike_year: data.reviews.received_from_owners[i].motorcycle.year,
        owner_location: data.reviews.received_from_owners[i].owner.location,
        motorcycle_location: data.reviews.received_from_owners[i].motorcycle.location,
        motorcycle_active: data.reviews.received_from_owners[i].motorcycle.state === 'active' ? true : false,
        owner_active: data.reviews.received_from_owners[i].owner.state === 'active' ? true : false,

        // "location":
      })
    }

    if (data.verified_email || data.verified_phone || data.verified_id) {
      getUserCleanData.hasVerifications = true
    } else {
      getUserCleanData.hasVerifications = false
    }

    if (data.reviews.received_from_riders.length > 0 || data.reviews.received_from_owners.length > 0) {
      getUserCleanData.hasReviews = true
    } else {
      getUserCleanData.hasReviews = false
    }
  }

  return getUserCleanData
}
