import { lazyWithRetry } from 'components/lazyUtil.js'

const Blog = lazyWithRetry(() => import('../../blog/feed/blog'))
const BlogArchive = lazyWithRetry(() => import('../../blog/archive/blogArchive.js'))
const BlogPost = lazyWithRetry(() => import('../../blog//singlePost/blogPost.js'))

const routes = [
  {
    path: '/blog/:page',
    component: Blog,
    is_private: false,
  },
  {
    path: '/blog/:page',
    component: Blog,
    is_private: false,
  },
  {
    path: '/blog',
    component: Blog,
    is_private: false,
  },
  {
    path: '/blog/posts/:post',
    component: BlogPost,
    is_private: false,
  },
  {
    path: '/blog/category/:category',
    component: BlogArchive,
    private: false,
  },
  {
    path: '/blog/category/:category/:page',
    component: BlogArchive,
    private: false,
  },
]

export default routes
