import moment from 'moment-timezone/builds/moment-timezone-with-data';

const yearOptionsPast = () => {
    let yearOptionsPast = []
    for(let i = 0; i < 25; i++) {
        yearOptionsPast.push({
            key: moment().add(i, 'years').format('YYYY').toString(),
            value: moment().add(i, 'years').format('YYYY').toString(),
            text: moment().add(i, 'years').format('YYYY').toString()  
        })
    }
    return yearOptionsPast
}

export const YEAR_OPTIONS_PAST = yearOptionsPast()