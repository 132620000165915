export const isUserInternational = (country, state) => {
  let isIntl = false;

  if (country !== "US") {
    isIntl = true;
  }

  if (country === "US") {
    if (
      state === "AS" ||
      state === "GU" ||
      state === "MP" ||
      state === "PR" ||
      state === "UM" ||
      state === "VI"
    ) {
      isIntl = true;
    }
  }

  if (country === "" || country === null || country === undefined) {
    isIntl = false;
  }

  return isIntl;
};
