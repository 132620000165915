import { lazyWithRetry } from 'components/lazyUtil.js'

const AccountWrap = lazyWithRetry(() => import('../../account/main/accountWrap'))
const UserProfile = lazyWithRetry(() => import('../../users/users.js'))
const SignUpPage = lazyWithRetry(() => import('components/auth/shells/signUpPage'))
const LoginPage = lazyWithRetry(() => import('../../auth/shells/loginPageContainer'))
const ResetPassword = lazyWithRetry(() => import('components/auth/passwords/resetPasswordContainer'))
const EmailToken = lazyWithRetry(() => import('components/auth/register/confirmEmail/emailToken'))
const SignOutPage = lazyWithRetry(() => import('components/auth/signOut/signOut'))
const PasswordTokenNew = lazyWithRetry(() => import('components/auth/passwords/passwordTokenNew'))
const Masquerade = lazyWithRetry(() => import('components/auth/masquerade/masquerade'))
// const AccountWrap = React.lazy(() => import("../../account/main/accountWrap"))
// const UserProfile = React.lazy(() => import("../../users/users.js"))
// const SignUpPage = React.lazy(() => import('components/auth/shells/signUpPage'))
// const LoginPage = React.lazy(() => import("../../auth/shells/loginPageContainer"))
// const ResetPassword = React.lazy(() => import("components/auth/passwords/resetPasswordContainer"))
// const EmailToken = React.lazy(() => import('components/auth/register/confirmEmail/emailToken'))
// const SignOutPage = React.lazy(() => import('components/auth/signOut/signOut'))
// const PasswordTokenNew = React.lazy(() => import("components/auth/passwords/passwordTokenNew"))

const routes = [
  {
    path: '/users/:userID',
    component: UserProfile,
    is_private: false,
  },
  {
    path: '/account',
    component: AccountWrap,
    is_private: true,
    is_mother: true,
  },
  {
    path: '/masquerade',
    component: Masquerade,
    is_private: true
  },
  {
    path: '/register',
    component: SignUpPage,
    is_private: false,
  },
  {
    path: '/login',
    component: LoginPage,
    is_private: false,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    is_private: false,
  },
  {
    path: '/reset-password/:token',
    component: PasswordTokenNew,
    is_private: false,
  },
  {
    path: '/confirm-email/:token',
    component: EmailToken,
    is_private: false,
  },
  {
    path: '/signout',
    component: SignOutPage,
    is_private: false,
  },
]

export default routes
