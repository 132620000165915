import moment from 'moment-timezone/builds/moment-timezone-with-data';

const timezone = () => {
    let timeZoneList = moment.tz.names()
    let timeZoneOptions = []
    for(var i=0; i<timeZoneList.length; i++) {
        timeZoneOptions.push({
          key: timeZoneList[i],
          value: timeZoneList[i],
          text: timeZoneList[i]    
        })
    }
    return timeZoneOptions
}

export const TIMEZONES = timezone()
