export const calculateAverageRateScore = (scoresArray) => {
  let sum,
    avg = 0;

  var scoreFiltered = scoresArray.filter(function(el) {
    return el != null;
  });

  if (scoreFiltered.length) {
    sum = scoreFiltered.reduce(function(a, b) {
      return a + b;
    });
    // avg = (sum / scoresArray.length) / 100;
    avg = sum / scoreFiltered.length;
  }

  return avg;
};
