export const find_price_N_cents = (dailyRentalPrice, dynamicDiscounts) => {
  let multi_day_N = {}
  if (dynamicDiscounts?.length > 0) {
    dynamicDiscounts.map(discount => {
      if (discount.discount_days >= 4) {
        multi_day_N.more_than_4_days = dailyRentalPrice - dailyRentalPrice * (discount.discount_percent / 100)
      } else if (discount.discount_days === 3) {
        multi_day_N.three_days = dailyRentalPrice - dailyRentalPrice * (discount.discount_percent / 100)
      } else if (discount.discount_days === 2) {
        multi_day_N.two_days = dailyRentalPrice - dailyRentalPrice * (discount.discount_percent / 100)
      } else {
        multi_day_N.one_day = dailyRentalPrice
      }
    })
  }
  return multi_day_N
}