import { apiErrorHandling } from "../apiErrorHandling";

export const apiDELETE = (data, config, url, processName, componentDesc, axios) => {

  return axios
      .delete(url, config)
      .then(function(response) {

        return response.data
      })
      .catch(function(error) {

        throw apiErrorHandling(
            "DELETE", 
            error, 
            data, 
            componentDesc,
            processName, 
            url
        )
      })
}
