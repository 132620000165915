const dateOptions = () => {
  let dateOptions = []
  for(let i = 1; i<32; i++) {
    dateOptions.push({
      key: i.toString(),
      value: i.toString(),
      text: i.toString()
    })
  }
  return dateOptions
}

export const DATE_OPTIONS = dateOptions()