import { lazyWithRetry } from 'components/lazyUtil.js'

const NotFound = lazyWithRetry(() => import('./../../../components/elements/errors/404.js'))

const routes = [
  {
    component: NotFound,
    is_private: false,
  },
]

export default routes
