import {
  motoPhotoID,
  motoPhotoURL,
  userPhotoID,
  userPhotoURL,
} from '../../../../constants/images/sizesAndQuality'
import moment from 'moment-timezone/builds/moment-timezone-with-data'
import { buildBikeURL } from './../../../utils'

export const getInsuranceNameFromID = (insuranceOptions, uuid) => {
  //insurance type can be decline or minimum

  let insuranceName = 'Insurance Name Missing'

  insuranceOptions.forEach((option, i) => {
    if (option.uuid === uuid) {
      //alert('found delcine')
      insuranceName = option.name
    }
  })

  return insuranceName
}

const financialsInfo = (data) => {
  //build payment methods
  let paymentMethods = []
  for (let i = 0; i < data.rider.financials.stored_payment_cards.length; i++) {
    paymentMethods.push({
      card_type: data.rider.financials.stored_payment_cards[i].brand,
      card_digits: data.rider.financials.stored_payment_cards[i].last_4,
      card_exp_date:
        data.rider.financials.stored_payment_cards[i].expiration_month +
        '/' +
        data.rider.financials.stored_payment_cards[i].expiration_year,
      payment_method_id: data.rider.financials.stored_payment_cards[i].uuid,
    })
  }

  return {
    showFreeDaysCredits:
      data.rider.financials.free_days > 0 ||
        data.rider.financials.twisted_road_credit.amount_cents > 0
        ? true
        : false,
    free_days_amount: data.rider.financials.free_days,
    credits_amount_cents:
      data.rider.financials.twisted_road_credit.amount_cents,
    credits_amount_currency:
      data.rider.financials.twisted_road_credit.amount_curency,
    payment_methods: paymentMethods,
    default_payment_method:
      data.rider.financials.stored_payment_cards.length > 0
        ? data.rider.financials.stored_payment_cards[0].uuid
        : '',
    has_multiday_discount: data.pricing.motorcycle.pricing.multi_day_discounts,
    multiday_discount_applied:
      data.pricing.request_details.multi_day_discount_applied,
    pricing: data.pricing,
    hold_amount: data.request.hold_amount,
    insurance_options_hold_amount: data.request.insurance_options_hold_amount,
    booked_through_dealer_testride:
      data.pricing.request_details.booked_through_dealer_testride,
  }
}

const optionsInfo = (data) => {
  return {
    daily_roadside_price_cents:
      data.pricing.available_services.road_side_assistance_fee
        .daily_road_side_assistance_cents,
    daily_roadside_price_currency:
      data.pricing.available_services.road_side_assistance_fee
        .daily_road_side_assistance_currency,

    tire_protection_price_cents:
      data.pricing.available_services.tire_protection_fee
        .daily_tire_protection_cents,
    tire_protection_price_currency:
      data.pricing.available_services.tire_protection_fee
        .daily_tire_protection_currency,

    insurance_options: data.pricing.available_services.rider_insurance_options,
    default_insurance: data.pricing.request_details.rider_insurance,
    default_insurance_name: getInsuranceNameFromID(
      data.pricing.available_services.rider_insurance_options,
      data.pricing.request_details.rider_insurance
    ),
  }
}

const reservationInfo = (data) => {
  const startTime = moment
    .utc(data.request.start_at)
    .tz(data.motorcycle.timezone)
    .format('H')
  const endTime = moment
    .utc(data.request.end_at)
    .tz(data.motorcycle.timezone)
    .format('H')

  return {
    reservation_id: data.request.id,
    reservation_start_date: data.request.start_at,
    reservation_end_date: data.request.end_at,
    reservation_start_time: startTime,
    reservation_end_time: endTime,
    request_uuid: data.request.uuid,

    start_time_list: data.request.pickup_timings
      ? data.request.pickup_timings
      : null,
    end_time_list: data.request.dropoff_timings
      ? data.request.dropoff_timings
      : null,
  }
}

const ownerInfo = (data) => {
  let ownerInfo = {
    bike_owner_name: data.owner.name,
    bike_owner_id: data.owner.pretty_id,
    bike_owner_avatar: userPhotoURL(data.owner.avatar.url),
    bike_owner_avatar_cloudinary_public_id: userPhotoID(
      data.owner.avatar.cloudinary_public_id
    ),
    is_partner: data.owner.is_partner,
    partner_type: null,
    is_same_day_pickup: false,
    partner_allow_under_25: null,
    bike_overall_rating: data.motorcycle.reviews_average,
    bike_review_count: data.motorcycle.reviews_count,
    bike_owner_email: data.owner.email,
  }

  let startMoment = moment(data.request.start_at)
    .tz(data.motorcycle.timezone)
    .format('MM-DD-YYYY')
  let today = moment()
    .tz(data.motorcycle.timezone)
    .format('MM-DD-YYYY')

  //here we make some dealer modifications
  if (data.owner.is_partner) {
    ownerInfo['bike_owner_name'] = data.owner.partner_info.partner_name
    ownerInfo['partner_type'] = data.owner.partner_info.partner_type
    ownerInfo['partner_allow_under_25'] =
      data.owner.partner_info.partner_allow_under_25
  }
  ownerInfo['is_same_day_pickup'] = startMoment === today ? true : false
  return ownerInfo
}

const motoInfo = (data) => {
  return {
    bike_uuid: data.motorcycle.uuid,
    bike_id: data.motorcycle.pretty_id,
    bike_make: data.motorcycle.make,
    bike_timezone: data?.owner?.is_partner
      ? data?.owner?.partner_info?.timezone
      : data.motorcycle.timezone,
    bike_model: data.motorcycle.model,
    bike_location: {
      location_region_abbr: data.motorcycle.location.region_abbreviation,
      location_city: data.motorcycle.location.city,
      location_country: data.motorcycle.location.country,
      location_country_abbr: data.motorcycle.location.country_abbreviation,
      // "latitude": 41.881832,
      // "longitude": -87.623177
    },
    bike_year: data.motorcycle.year,
    bike_nickname: data.motorcycle.nickname,
    bike_purchase_price_cents: data.motorcycle.financials.purchase_price_cents,
    bike_daily_price_cents: data.motorcycle.financials.daily_rental_price_cents,
    bike_daily_price_currency:
      data.motorcycle.financials.daily_rental_price_currency,
    bike_gear_available: [],
    bike_url: buildBikeURL({
      country: data.motorcycle.location.country_abbreviation,
      region: data.motorcycle.location.region_abbreviation,
      city: data.motorcycle.location.city,
      year: data.motorcycle.year.toString(),
      make: data.motorcycle.make,
      model: data.motorcycle.model,
      bikeID: data.motorcycle.pretty_id,
    }),
    bike_image_url: motoPhotoURL(data.motorcycle.avatar_image.url),
    bike_image_cloudinary_public_id: motoPhotoID(
      data.motorcycle.avatar_image.cloudinary_public_id
    ),
    bike_state: data.motorcycle.state,
    bike_dealer_access_restriction: data.motorcycle.dealer_access_restriction,
    bike_dealer_access_token: data.motorcycle.dealer_access_token,
  }
}

const deliveryInfo = (data) => {
  return {
    delivery_fees_information: data.pricing.request_details.delivery_fees_information,
  }
}

export const getPaymentTransformer = (data) => {
  return {
    ...motoInfo(data),
    ...reservationInfo(data),
    ...ownerInfo(data),
    ...optionsInfo(data),
    ...financialsInfo(data),
    ...deliveryInfo(data)
  }
}

export const getTripModificationTransformer = (data) => {
  return {
    currentTripTotal: data.pricing.current_grand_totals,
    newTripTotal: data.pricing.new_grand_totals,
    difference: data.pricing.difference
  }
}
