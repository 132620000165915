import { lazyWithRetry } from 'components/lazyUtil.js'

const Dashboard = lazyWithRetry(() => import('../../dashboard/dashboardIndex'))
const Trips = lazyWithRetry(() =>
  import('components/dashboard/trips/myTripsFeed')
)
const Listings = lazyWithRetry(() =>
  import('components/dashboard/listings/listingFeed')
)
const Reservations = lazyWithRetry(() =>
  import('../../dashboard/reservations/reservationsFeed')
)
const ReservationDetails = lazyWithRetry(() =>
  import('../../dashboard/reservations/reservationDetails/reservationDetails')
)
const Favorites = lazyWithRetry(() =>
  import('../../dashboard/favorites/favorites')
)
const EditBikeListing = lazyWithRetry(() =>
  import('../../list/motorcycle/edit/motoEditForm')
)
const TripDetails = lazyWithRetry(() =>
  import('components/dashboard/trips/myTripsDetails/myTripsDetails')
)
const BikeListingSuccess = lazyWithRetry(() =>
  import('components/list/motorcycle/success/motorcycleSuccess')
)
const PaymentSuccess = lazyWithRetry(() =>
  import('../../payments/success/paymentSuccess.js')
)
const ListView = lazyWithRetry(() =>
  import('./../../dashboard/favorites/listView.js')
)
const ModifyTrip = lazyWithRetry(() =>
  import('components/dashboard/trips/modifyTrip/modifyTrip')
)
const CancelTrip = lazyWithRetry(() =>
  import('components/dashboard/trips/cancelTrip/cancelTrip')
)

const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    is_private: true,
  },
  {
    path: '/dashboard/reservations',
    component: Reservations,
    is_private: true,
  },
  {
    path: '/dashboard/reservations/:reservationID',
    component: ReservationDetails,
    is_private: true,
  },
  {
    path: '/dashboard/mytrips',
    component: Trips,
    is_private: true,
  },
  {
    path: '/dashboard/mytrips/itinerary/:reservationID',
    component: TripDetails,
    is_private: true,
  },

  {
    path: '/dashboard/mytrips/itinerary/:reservationID/success',
    component: PaymentSuccess,
    is_private: true,
  },
  {
    path: '/dashboard/mytrips/itinerary/:reservationID/modify',
    component: ModifyTrip,
    is_private: true,
  },
  {
    path: '/dashboard/mytrips/itinerary/:reservationID/cancel',
    component: CancelTrip,
    is_private: true,
  },
  {
    path: '/dashboard/garage',
    component: Listings,
    is_private: true,
  },
  {
    path: '/dashboard/garage/edit/:bikeID/success',
    component: BikeListingSuccess,
    is_private: true,
  },
  {
    path: '/dashboard/garage/edit/:bikeID',
    component: EditBikeListing,
    is_private: true,
  },
  {
    path: '/dashboard/favorites',
    component: Favorites,
    is_private: true,
  },
  {
    path: '/dashboard/favorites/list/:wishlistId/:listName',
    component: ListView,
    is_private: true,
  },
]

export default routes
