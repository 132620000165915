const provinces = require('provinces')

const usStatesCanadaProvinces = () => {

    let statesProvinces = []
  
    for(var i=0; i<provinces.length; i++) {
      if(provinces[i].country === 'US' || provinces[i].country === 'CA') {
          statesProvinces.push({
            key: provinces[i].short,
            value: provinces[i].short,
            text: provinces[i].name           
          })
      }
    }
    return statesProvinces  
}

export const US_STATES_CANADA_PROVINCES = usStatesCanadaProvinces()