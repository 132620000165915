import React from 'react'
import { isEmpty } from '../../utils'
import NumberFormat from 'react-number-format'
import { convertCents } from 'components/utils/convertCents'
import getSymbolFromCurrency from 'currency-symbol-map'
import {
  USER_SMALL_PARAMS,
  motoPhotoID,
  userPhotoID,
  SEARCH_IMAGE_PARAMS,
  USER_LARGE_PARAMS,
} from 'constants/images/sizesAndQuality'
import { CLOUDINARY_NAME } from 'constants/images/cloudinary'
import cloudinary from 'cloudinary-core'
import { buildBikeURL } from '../../utils/buildBikeURL'
import moment from 'moment'
import PENDING_STATES from 'components/dashboard/utils/pendingStates'
import RIDE_MODIFICATION_PENDING_STATES from 'components/dashboard/utils/rideModificationStates'
const cl = new cloudinary.Cloudinary({
  cloud_name: CLOUDINARY_NAME,
})

const riderAvatarURL = (avatarID) => cl.url(userPhotoID(avatarID), USER_SMALL_PARAMS)

const riderAvatarURLLarge = (avatarID) => cl.url(userPhotoID(avatarID), USER_LARGE_PARAMS)

const motorcycleImageURL = (motoID) => cl.url(motoPhotoID(motoID), SEARCH_IMAGE_PARAMS)

const FormatPrice = ({ cents, currency }) => (
  <NumberFormat
    defaultValue={convertCents(cents)}
    displayType={'text'}
    decimalScale={0}
    thousandSeparator={true}
    prefix={getSymbolFromCurrency(currency)}
  />
)

const configureActions = (data, actions, state) => {
  if (state === 'expired') {
    //hack: if expired make sure they get nothing
    return []
  }

  let tripActions = actions
  //below is code to hack report damage only showing up with certain constraints.
  //this should be controlled by the backend, ideally.
  
  let endPlus48Hours = moment.tz(data.reservation.end_at, data.reservation.timezone_of_bike_at_time_booking).add('48', 'hours')
  let today_date = moment.tz(data.reservation.timezone_of_bike_at_time_booking)
  let isBeforeCompletePlus48Hours = today_date.isBefore(endPlus48Hours)
  let reportDamageStates = ['in_progress', 'complete', 'leave_feedback']
  let canReportDamage = reportDamageStates.includes(state)
  let existingReportDamage = data.request.can_report_damage

  if (canReportDamage && isBeforeCompletePlus48Hours && existingReportDamage) {
    //if they can report damage
    tripActions.push('report_damage')
  }

  return tripActions
  /*return [ 
      "start_trip",
     "approve",
      "decline",
      "leave_feedback",
    "report_damage",
      "canceled"
    ]*/
}

const testState = (data) => {
  let state = data.reservation.state
  let actions = data.reservation.reservation_actions

  if (PENDING_STATES.includes(state)) {
    //hack: then we check that end date. if it's after today + it's pending, we 'expire it'
    let startDate = data.reservation.start_at
    if (moment().isAfter(moment(startDate).add('3', 'hours'))) {
      return 'expired'
    }
  }

  //hack: we do a second check, this time to sniff if we have a leave_feedback action open
  if (actions.includes('leave_feedback') && state !== 'complete' && state !== 'in_progress') {
    //we check to make sure that the state is not complete. hobnob sets the state complete, but doesn't set the actions
    //though having in_progress tied to start trip pretty much negates this.
    return 'start_trip'
  }

  return state
}

const reservationInfo = (data) => {
  return {
    reservation_actions: configureActions(data, data.reservation.reservation_actions, testState(data)),
    reservation_total_cents: data.request.pricing.totals.owner_revenue.owner_revenue_cents,
    reservation_total_currency: data.request.pricing.totals.owner_revenue.owner_revenue_currency,
    reservation_id: data.reservation.uuid,
    reservation_pretty_id: data.pretty_id,
    reservation_state: testState(data),
    reservation_start: data.reservation.start_at,
    reservation_end: data.reservation.end_at,
    reservation_created: data.reservation.created_at,
  }
}

const motoInfo = (data) => {
  return {
    bike_id: data.motorcycle.pretty_id,
    bike_uuid: data.motorcycle.uuid,
    bike_make: data.motorcycle.make,
    bike_model: data.motorcycle.model,
    bike_year: data.motorcycle.year,
    bike_nickname: data.motorcycle.nickname,
    bike_delivery_fees_information:
      data.request.pricing.request_details.delivery_fees_information,
    bike_image: motorcycleImageURL(data.motorcycle.images[0].cloudinary_public_id),
    bike_timezone: data.reservation.timezone_of_bike_at_time_booking
      ? data.reservation.timezone_of_bike_at_time_booking
      : 'America/Chicago',
    location: {
      city: data.motorcycle.location.city,
      region: data.motorcycle.location.region,
      region_abbreviation: data.motorcycle.location.region_abbreviation,
      country: data.motorcycle.location.country,
      country_abbreviation: data.motorcycle.location.country_abbreviation,
    },
    motorcycle_vin: data.motorcycle.vin,
    motorcycle_active: data.motorcycle.state === 'active' ? true : false,
    motorcycle_url: buildBikeURL({
      country: data.motorcycle.location.country_abbreviation,
      region: data.motorcycle.location.region_abbreviation,
      city: data.motorcycle.location.city,
      year: data.motorcycle.year.toString(),
      make: data.motorcycle.make,
      model: data.motorcycle.model,
      bikeID: data.motorcycle.pretty_id,
    }),
    motorcycle_edit_url: '/dashboard/garage/edit/' + data.motorcycle.uuid,
  }
}

const ownerInfo = (data) => {
  return {
    owner_payout_cents: data.request.pricing.totals.owner_revenue.owner_revenue_cents,
    owner_payout_currency: data.request.pricing.totals.owner_revenue.owner_revenue_currency,
    owner_payout_total: (
      <FormatPrice
        cents={data.request.pricing.totals.owner_revenue.owner_revenue_cents}
        currency={data.request.pricing.totals.owner_revenue.owner_revenue_currency}
      />
    ),
    owner_uuid: data.owner.uuid,
    owner_w9_requests: data.owner?.w9_requests,
    owner_payout_configured: data.owner.bank_account_configured
  }
}

const riderInfo = (data) => {
  return {
    renter_id: data.rider.pretty_id,
    renter_url: '/users/' + data.rider.pretty_id,
    renter_name: data.rider.name,
    renter_state: data.rider.state,
    renter_phone: data.rider.phone ? String(data.rider.phone) : null,
    renter_country: data.rider.location.country_abbreviation,
    renter_city: data.rider.location.city,
    renter_region: data.rider.location.region_abbreviation,
    renter_rating: Math.round(data.rider.overall_rating * 10) / 10,
    renter_avatar: riderAvatarURL(data.rider.avatar.cloudinary_public_id),
    renter_avatar_large: riderAvatarURLLarge(data.rider.avatar.cloudinary_public_id),
    renter_avatar_cloudinary_public_id: riderAvatarURL(data.rider.avatar.cloudinary_public_id),
    renter_location: isEmpty(data.rider.location.city)
      ? null
      : data.rider.location.city + ', ' + data.rider.location.region_abbreviation,
    renter_verifications_email: data.rider.verified.verified_email,
    renter_verifications_phone: data.rider.verified.verified_phone,
    renter_verifications_id: data.rider.verified.verified_id,
    renter_message: isEmpty(data.reservation.request_message_from_rider)
      ? ''
      : data.reservation.request_message_from_rider,
    rider_experiences: [],
    renter_resume: data.rider.rider_resume,
    rider_active: data.rider.state === 'active' ? true : false,
    rider_uuid: data.rider.uuid
  }
}

const requestInfo = data => {
  return { 
    request_id: data.request.id, 
    request_uuid: data.request.uuid, 
    chat_session: data.request.chat_session,
    request_state: data.request.state
  }
}

const rideModificationRequestInfo  = data => {
  let modification_request = null
  const filteredRideModificationPendingStates = RIDE_MODIFICATION_PENDING_STATES.filter(state => {
    return !["pending_owner_approval", "declined"].includes(state)
  })
  if (filteredRideModificationPendingStates.includes(data.request.state) || !(data.ride_modification_request.id)) {
    modification_request = null
  } else {
    modification_request = {
      id: data.ride_modification_request.id,
      new_start_at: data.ride_modification_request.new_start_at,
      new_end_at: data.ride_modification_request.new_end_at,
      comment: data.ride_modification_request.comment
    }
  }
  return {
    ride_modification_request: modification_request
  }
}

export const reservationsDetailsFormatted = (data) => {
  return {
    ...riderInfo(data),
    ...motoInfo(data),
    ...reservationInfo(data),
    ...ownerInfo(data),
    ...requestInfo(data),
    ...rideModificationRequestInfo(data)
  }
}
