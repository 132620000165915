import { buildBikeURL, convertZeroToEmpty } from '../../../utils'
import {
  motoPhotoID,
  motoPhotoURL,
} from '../../../../constants/images/sizesAndQuality'

import {
  YEAR,
  MAKE,
  MODEL,
  ISSUING_COUNTRY,
  ISSUING_STATE,
  INVENTORY_ID,
  CATEGORY,
  LICENSE_PLATE,
  ODOMETER,
  PARKING_TYPE,
} from '../steps/basicDetails/basicUtils/basicConstants.js'
import {
  ENGINE,
  HEIGHT,
  WEIGHT,
  FUEL_TANK_CAPACITY,
  MILES_BEFORE_REFUEL,
  TIRES_TREAD,
  LAST_SERVICE,
} from '../steps/specifications/specsUtils/specsConstants.js'
import {
  BIKE_DESCRIPTION,
  BIKE_NICKNAME,
  PRIVATE_BIKE_INFORMATION,
} from '../steps/aboutDetails/detailsUtils/aboutDetailsConstants.js'
import {
  DAILY_PRICE,
  MULTIDAY_DISCOUNT,
} from '../steps/price/priceUtils/priceConstants.js'
import { PHOTOS } from '../steps/uploadPhotos/photoUtils/photoConstants.js'
import {
  ADDRESS_LINE_1,
  ADDRESS_LINE_2,
  ADDRESS_CITY,
  ADDRESS_REGION,
  ADDRESS_COUNTRY,
  ADDRESS_POSTAL_CODE,
  TIMEZONE,
} from '../steps/address/addressUtils/addressConstants.js'
import { HELMET } from '../steps/helmet/helmetUtils/helmetConstants.js'
import {
  ABLE_TO_DELIVER,
  FLAT_DELIVERY_FEES,
  MAX_DELIVERY_DISTANCE,
  PRICE_PER_MILE,
} from './../steps/enableDeliveryListBike/constants'

const parsedState = (state) => {
  let parsedState = state

  parsedState = state === 'pending' ? 'incomplete' : state
  parsedState = state === 'inactive' ? 'inactive' : state //we use to call this delisted, now we just call it in active
  return parsedState
}

export const listingEditAPITransformerNew = (data) => {
  let listingData = {}

  listingData['user_id'] = data.owner.pretty_id
  listingData['motorcycle_state'] = parsedState(data.state)
  listingData['state'] = parsedState(data.state)
  listingData['motorcycle_pretty_id'] = data.pretty_id
  listingData['motorcycle_uuid_id'] = data.uuid
  listingData['multiday_pricing'] =
    Object.keys(data?.multi_day_pricing).length === 0
      ? null
      : data?.multi_day_pricing.reverse()

  listingData[YEAR] = data.year.toString()
  listingData[MAKE] = data.make
  listingData[MODEL] = data.model
  listingData[ISSUING_COUNTRY] = data.license_plate_country_abbreviation
  listingData[ISSUING_STATE] = data.license_plate_state_abbreviation
  listingData[LICENSE_PLATE] = data.license_plate
  listingData[CATEGORY] = data.category
  listingData[INVENTORY_ID] = ''
  listingData[ODOMETER] = convertZeroToEmpty(data.odometer_miles)
  listingData[PARKING_TYPE] = data.parking_type

  listingData[BIKE_NICKNAME] = data.nickname
  listingData[BIKE_DESCRIPTION] = data.description
  listingData[PRIVATE_BIKE_INFORMATION] = data.rental_notes

  listingData[PHOTOS] = []

  listingData[DAILY_PRICE] = convertZeroToEmpty(
    data.daily_rental_price_cents / 100
  )
  listingData[MULTIDAY_DISCOUNT] = data.multi_day_discounts
  listingData['bike_daily_rate_currency'] = data.daily_rental_price_currency

  listingData['for_sale'] = data.purchase_information.purchasable
  listingData['contact_for_sale'] = data.purchase_information.contact_for_sale
  listingData['high_value'] = data.purchase_information.high_value
  listingData['sale_price'] = data.purchase_information.purchase_price_cents

  listingData[TIMEZONE] = data.timezone
  listingData[ADDRESS_LINE_1] = data.location.address_street_1
  listingData[ADDRESS_LINE_2] = data.location.address_street_2
  listingData[ADDRESS_CITY] = data.location.city
  listingData[ADDRESS_REGION] = data.location.region_abbreviation
  listingData[ADDRESS_COUNTRY] = data.location.country_abbreviation
  listingData[ADDRESS_POSTAL_CODE] = data.location.postal_code

  listingData[ENGINE] = convertZeroToEmpty(data.displacement_cc)
  listingData[HEIGHT] = convertZeroToEmpty(data.seat_height_in)
  listingData[WEIGHT] = convertZeroToEmpty(data.weight_lbs)
  listingData[FUEL_TANK_CAPACITY] = convertZeroToEmpty(data.fuel_capacity_gal)
  listingData[MILES_BEFORE_REFUEL] = convertZeroToEmpty(data.fuel_capacity_mi)
  listingData[TIRES_TREAD] = data.tire_tread
  listingData[LAST_SERVICE] = data.last_serviced_date
  listingData[ABLE_TO_DELIVER] = data.can_deliver
  listingData[FLAT_DELIVERY_FEES] =
    data.flat_delivery_fees.flat_delivery_fees_cents
  listingData[MAX_DELIVERY_DISTANCE] = data.max_delivery_distance_in_miles
  listingData[PRICE_PER_MILE] = data.price_per_mile.price_per_mile_cents

  if (data.gear) {
    if (data.gear.helmets) {
      listingData[HELMET] = data.gear.helmets
    }
  }

  for (let i = 0; i < data.images.length; i++) {
    listingData[PHOTOS].push({
      secure_url: motoPhotoURL(data.images[i].url),
      cloudinary_public_id: motoPhotoID(data.images[i].cloudinary_public_id),
    })
  }

  listingData['motorcycle_url'] = buildBikeURL({
    country: data.location.country_abbreviation,
    region: data.location.region_abbreviation,
    city: data.location.city,
    year: data.year.toString(),
    make: data.make,
    model: data.model,
    bikeID: data.pretty_id,
  })

  return listingData
}
