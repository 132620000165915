//Date and Time Constants
export { BIRTH_MONTH_OPTIONS } from './dateAndTime/birthMonthOptions'
export { DATE_OPTIONS } from './dateAndTime/dateOptions'
export { TIME_OPTIONS as timeOptions } from './dateAndTime/timeOptions'
export { TIMEZONES } from './dateAndTime/timezoneOptions'
export { YEAR_BIRTH_OPTIONS } from './dateAndTime/yearBirthOptions'
export { YEAR_OPTIONS } from './dateAndTime/yearOptions'
export { YEAR_OPTIONS_PAST } from './dateAndTime/yearOptionsPast'

//Geography
export { CANADA_PROVINCES } from './geography/canadianProvinces'
export { COUNTRIES as WORLD_COUNTRIES } from './geography/countries'
export { WORLD_COUNTRY_CODES } from './geography/countyPhoneCodes'
export { US_STATES_CANADA_PROVINCES } from './geography/unitedStatesAndCanadaProvines'
export { US_STATES } from './geography/usStates'

//Motorcycles
export {
  BIKE_BRANDS as bikeBrands,
  BIKE_BRANDS_RAW as bikeBrandsMasterList,
} from './motorcycles/brands'
export {
  BIKE_CATEGORIES as bikeCategories,
  BIKE_CATEGORIES_ARRAY as bikeCategoriesArray,
} from './motorcycles/categories'
export { LISTING_OPTIONS_YEARS } from './motorcycles/listingOptionsYears'
export { RIDER_EXP_YEAR_OPTIONS } from './motorcycles/riderExperienceYears'
export {
  BIKE_PARKING as bikeParking,
  BIKE_PARKING_RAW as parkingTypesMasterList,
} from './motorcycles/parkingTypes'

//Payments
export { CARD_MONTH_OPTIONS } from './payments/cardMonthOptions'

//Users
export { GENDER_OPTIONS } from './users/gender'

// Motorcycle Events
export {
  MOTORCYCLE_EVENT_TYPES,
  MOTORCYCLE_EVENT_TYPES_MASTER_LIST,
} from './motorcycleEvents/eventTypes'

//Utils are left independent
export { MAP_SIZES_TO_PROPS } from './utils/mapSizesToProps'

//TODO: System: Since there are so many, use system constants directly from
//Thier directory in ./system
//But for now...
export const BUILD_ENV = process.env.REACT_APP_ENV
export const API_URL = process.env.REACT_APP_API_URL
export const MOCK_URL = process.env.REACT_APP_MOCK_URL
export const WS_URL = process.env.REACT_APP_WS_URL
export const AIRBRAKE_API_KEY = process.env.REACT_APP_AIRBRAKE_API_KEY
export const AIRBRAKE_PROJECT_ID = process.env.REACT_APP_AIRBRAKE_PROJECT_ID
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
export const MAPBOX_API_KEY = process.env.REACT_APP_MAP_BOX_API_KEY
export const MIXPANEL_ID = process.env.REACT_APP_MIXPANEL_ID
export const PERSONA_ID = process.env.REACT_APP_PERSONA_ID
export const PERSONA_ENV = process.env.REACT_APP_PERSONA_ENV
export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const MAX_RATING = 5
export const API_CONSOLE_DEBUG = process.env.REACT_APP_API_CONSOLE_DEBUG
export const AFTER_LOGIN_DEFAULT_LANDING = '/'
export const AFTER_LOGOUT_DEFAULT_LANDING = '/'
export const KLAVIYO_PUBLIC_API_KEY = process.env.REACT_APP_KLAVIYO_PUBLIC_API_KEY

export const BLACKLISTED_ERRORS = [
  'Blocked a frame with origin "https://www.twistedroad.com" from accessing a cross-origin frame. Protocols, domains, and ports must match.',
  'GEOCODER_GEOCODE: ERROR: A geocoding request could not be processed due to a server error. The request may succeed if you try again.',
]
