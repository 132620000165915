import mixpanel from 'mixpanel-browser'
import moment from 'moment'
import TagManager from 'react-gtm-module'

export const trackViewSignUpForm = (view) => {
  mixpanel.track('Viewed Sign Up Form', {
    sign_up_page_type: view.signup_type, //modal, page, etc.
  })
}

//This tracks where they bounced from the sign up wizard.
export const trackSignUpWizardClosed = (params) => {
  mixpanel.track('Sign Up Wizard Closed', {
    sign_up_screen: params.screen,
  })
}

//Basic Page
export const trackSignUp = (params) => {
  mixpanel.alias(params.pretty_id)
  mixpanel.track('Signed Up')
  mixpanel.track('Logged In')
  mixpanel.people.set({
    $name: params.first_name + ' ' + params.last_name,
    $email: params.email,
    'First Name': params.first_name,
    'Last Name': params.last_name,
    Logins: 1,
    'Signed Up Through Referral': params.sign_up_through_referrals
      ? true
      : false,
  })

  if (params.sign_up_through_referrals) {
    mixpanel.people.set({
      'Referral Code at Sign Up': params.ref_code,
    })
  }

  mixpanel.people.set_once({
    'Sign Up Date': moment().toISOString(),
  })

  mixpanel.identify(params.pretty_id)
  TagManager.dataLayer({
    dataLayer: {
      event: 'fireTREvent',
      trEventCategory: 'Conversion',
      trEventAction: 'Success',
      trEventLabel: 'New User Registration',
    },
  })
}

export const trackSignUpWizardScreen = (params) => {
  //TO DO LAST PAGE IS NOT DO WHAT I WANT IT TO DO. NOT CALLING THE CALLBACK
  if (params.use_callback) {
    mixpanel.track(
      'Sign Up Wizard Screen Completed',
      {
        sign_up_screen: params.screen,
        screen_number: params.step_number,
      },
      {},
      params.callback
    )
  } else {
    mixpanel.track('Sign Up Wizard Screen Completed', {
      sign_up_screen: params.screen,
      screen_number: params.step_number,
    })
  }
}

export const trackSignUpWizardScreenAttempt = (desc, params, screenNumber) => {
  mixpanel.track('Sign Up Wizard Screen Attempted', {
    sign_up_screen: desc,
    sub_event: params.attempt_desc,
    error_message: params.error_messsage,
    error_code: params.error_code,
    error_type: params.error_type, //user, server, other
    screen_number: screenNumber,
  })
}

export const signUpScreenSkipped = (desc, screenNumber) => {
  mixpanel.track('Sign Up Screen Skipped', {
    sign_up_screen: desc,
    screen_number: screenNumber,
  })
}
