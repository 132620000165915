export const bikeBrandsMasterList = [
  "Aprilia",
  "BMW",
  "BSA",
  "Big Dog",
  "Bourget",
  "Buell",
  "Can-Am",
  "Ducati",
  "Genuine",
  "Harley-Davidson",
  "Honda",
  "Husqvarna",
  "Icebear",
  "Indian",
  "Janus",
  "KTM",
  "Kawasaki",
  "MV Agusta",
  "Moto Guzzi",
  "Norton",
  "Royal Enfield",
  "SYM",
  "Suzuki",
  "Swift Chopper",
  "Triumph",
  "Ural",
  "Victory",
  "Yamaha",
  "Zero",
  "Zongshen",
];

function bikeBrandsList() {
  let bikeBrandsArray = [];

  for (let x = 0; x < bikeBrandsMasterList.length; x++) {
    bikeBrandsArray.push({
      key: x,
      value: bikeBrandsMasterList[x],
      text: bikeBrandsMasterList[x],
    });
  }

  return bikeBrandsArray;
}

export const BIKE_BRANDS = bikeBrandsList();
export const BIKE_BRANDS_RAW = bikeBrandsMasterList;

export const bikeBrands = [
  'harley-davidson',
  'triumph',
  'bmw',
  'ducati',
  'honda',
  'indian'
]

export const popularMotorcycleBrands = [
  {
    name: 'Harley-Davidson',
    imagePath: 'https://res.cloudinary.com/twisted-road/image/upload/c_fill,g_center,q_auto,f_auto,w_425,h_250/v1721158577/frontend_assets/brandImages/harleyDavidson.webp',
    alt: 'Harley-Davidson',
    url: 'harley-davidson-rental'
  },
  {
    name: 'Triumph',
    imagePath: 'https://res.cloudinary.com/twisted-road/image/upload/c_fill,g_center,q_auto,f_auto,w_425,h_250/v1721158578/frontend_assets/brandImages/triumph.webp',
    alt: 'Triumph',
    url: 'triumph-rental'
  },
  {
    name: 'BMW',
    imagePath: 'https://res.cloudinary.com/twisted-road/image/upload/c_fill,g_center,q_auto,f_auto,w_425,h_250/v1721158577/frontend_assets/brandImages/bmw.webp',
    alt: 'BMW',
    url: 'bmw-rental'
  },
  {
    name: 'Ducati',
    imagePath: 'https://res.cloudinary.com/twisted-road/image/upload/c_fill,g_center,q_auto,f_auto,w_425,h_250/v1721158577/frontend_assets/brandImages/ducati.webp',
    alt: 'Ducati',
    url: 'ducati-rental'
  },
  {
    name: 'Honda',
    imagePath: 'https://res.cloudinary.com/twisted-road/image/upload/c_fill,g_center,q_auto,f_auto,w_425,h_250/v1721158579/frontend_assets/brandImages/honda.webp',
    alt: 'Honda',
    url: 'honda-rental'
  },
  {
    name: 'Indian',
    imagePath: 'https://res.cloudinary.com/twisted-road/image/upload/c_fill,g_center,q_auto,f_auto,w_425,h_250/v1721158579/frontend_assets/brandImages/indian.webp',
    alt: 'Indian',
    url: 'indian-rental'
  }
]

export const sixPopularMotorcycleBrands = [
  {
    name: 'Harley-Davidson',
    imagePath: '/static/images/brands/logos/harley-davidson.png',
    alt: 'Harley-Davidson',
    url: 'harley-davidson-rental',
    listingName: 'harley-davidson'
  },
  {
    name: 'Triumph',
    imagePath: '/static/images/brands/logos/triumph.png',
    alt: 'Triumph',
    url: 'triumph-rental',
    listingName: 'triumph'
  },
  {
    name: 'Honda',
    imagePath: '/static/images/brands/logos/honda.png',
    alt: 'Honda',
    url: 'honda-rental',
    listingName: 'honda'

  },
  {
    name: 'Ducati',
    imagePath: '/static/images/brands/logos/ducati.png',
    alt: 'Ducati',
    url: 'ducati-rental',
    listingName: 'ducati'
  },
  {
    name: 'BMW',
    imagePath: '/static/images/brands/logos/bmw.png',
    alt: 'BMW',
    url: 'bmw-rental',
    listingName: 'bmw'
  },
  {
    name: 'Indian',
    imagePath: '/static/images/brands/logos/indian.png',
    alt: 'Indian',
    url: 'indian-rental',
    listingName: 'indian'
  }
]
