import React, { Component } from 'react'
import Modal from 'react-bulma-components/lib/components/modal'
import Box from 'react-bulma-components/lib/components/box'
import classNames from 'classnames'
import './modal.sass'
import PropTypes from 'prop-types'
import withSizes from 'react-sizes'
import { MAP_SIZES_TO_PROPS } from 'constants/utils/mapSizesToProps'

/*
This component is a wrapper for the bulma react modal.
It allows us to control things a little more. A write a little less code.
*/

class TwistedModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (this.props.show) {
      document.body.classList.add('modal-open')
      document.documentElement.classList.add('modal-open')
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open')
    document.documentElement.classList.remove('modal-open')
  }

  componentDidUpdate() {
    if (this.props.show) {
      document.body.classList.add('modal-open')
      document.documentElement.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
      document.documentElement.classList.remove('modal-open')
    }
  }

  onClose = () => {
    document.body.classList.remove('modal-open')
    document.documentElement.classList.remove('modal-open')
    this.props.onClose()
  }

  render() {
    const {
      noPadding,
      background,
      size,
      card,
      contentClassName,
      boxClassName,
      //cardTitle,
      cardBody,
      cardActions,
      closeOnEsc,
      closeOnBlur,
      showClose,
      show,
      //isMobile,
      mobileHeight,
      fixedAction,
      animation,
      beforeBox,
      boxStyle,
      mobileAnimation,
      header,
    } = this.props

    let modalShellClass = classNames('modal-shell', null, {
      'animate__animated animate__faster':
        mobileAnimation && this.props.isMobile,
      animate__slideInUp: mobileAnimation === 'up' && this.props.isMobile,
      animate__slideInDown: mobileAnimation === 'down' && this.props.isMobile,
      animate__slideInRight: mobileAnimation === 'right' && this.props.isMobile,
      animate__slideInLeft: mobileAnimation === 'left' && this.props.isMobile,
      animate__fadeIn: mobileAnimation === 'fade' && this.props.isMobile,
    })

    let contentClass = classNames('modal-content-wrap', contentClassName, {
      [`is-${size}-modal`]: size && !this.props.isMobile,
      'is-medium-modal': !size && !this.props.isMobile,
      'is-fullscreen-modal':
        this.props.isMobile && mobileHeight === 'fullscreen',
      'is-fullscreen-modal-height-auto':
        this.props.isMobile && mobileHeight === 'auto',
      'large-modal': this.props.images,
    })

    let boxClass = classNames('modal-box', null, boxClassName, {
      [`is-${size}-modal`]: size && !this.props.isMobile,
      'is-medium-modal': !size && !this.props.isMobile,
      'is-fullscreen-modal':
        this.props.isMobile && mobileHeight === 'fullscreen',
      'is-fullscreen-modal-height-auto':
        this.props.isMobile && mobileHeight === 'auto',
      'p-0': noPadding,
      'no-bg': !background,
      'custom-image-modal': this.props.images === false,
      'animate__animated animate__faster': animation && !this.props.isMobile,
      animate__slideInUp: animation === 'up' && !this.props.isMobile,
      animate__slideInDown: animation === 'down' && !this.props.isMobile,
      animate__slideInRight: animation === 'right' && !this.props.isMobile,
      animate__slideInLeft: animation === 'left' && !this.props.isMobile,
      animate__fadeIn: animation === 'fade' && !this.props.isMobile,
    })

    let closeClass = classNames('delete modal-close-x', this.props.closeClass, {
      'is-large': size === 'fullscreen' || this.props.isMobile,
      'modal-close-floating': noPadding || !header,
      'modal-with-tabs': this.props.modalWithTabs,
    })

    return (
      <Modal
        show={show}
        closeOnEsc={closeOnEsc}
        closeOnBlur={closeOnBlur}
        onClose={this.onClose}
        showClose={false}
        className={modalShellClass}
      >
        {!card && (
          <Modal.Content className={contentClass}>
            {beforeBox && beforeBox}
            <Box className={boxClass} style={boxStyle}>
              {header && showClose && !noPadding && (
                <div className="modal-header-wrapper">
                  {header && (
                    <h2 className="is-size-4 has-text-weight-bold">{header}</h2>
                  )}

                  {showClose && (
                    <span
                      className={closeClass}
                      onClick={(e) => {
                        e.preventDefault()
                        this.onClose()
                      }}
                    ></span>
                  )}
                </div>
              )}
              {showClose && !header && (
                <span
                  className={closeClass}
                  onClick={(e) => {
                    e.preventDefault()
                    this.onClose()
                  }}
                ></span>
              )}

              {this.props.children}
              {fixedAction && <div className="fixed-action">{fixedAction}</div>}
            </Box>
          </Modal.Content>
        )}

        {card && (
          <Modal.Card>
            {header && (
              <Modal.Card.Head onClose={this.onClose}>
                <Modal.Card.Title>{header}</Modal.Card.Title>{' '}
                {/*Format this, we haven't used it yet, though.*/}
              </Modal.Card.Head>
            )}
            <Modal.Card.Body>{cardBody}</Modal.Card.Body>
            {cardActions && <Modal.Card.Foot>{cardActions}</Modal.Card.Foot>}
          </Modal.Card>
        )}
      </Modal>
    )
  }
}

TwistedModal.defaultProps = {
  fullScreenMobile: false,
  noPadding: false,
  background: true,
  size: 'medium', //tiny
  card: false,
  header: null,
  cardBody: null,
  contentClass: null,
  cardActions: null,
  closeOnEsc: false,
  closeOnBlur: false,
  showClose: true,
  mobileHeight: 'fullscreen',
  show: false,
  beforeBox: null,
  boxStyle: null,
  closeClass: null,
  animation: null,
  mobileAnimation: null,
  fixedAction: null,
  onClose: () => {},
  domRef: {}, //need to pass down the bulma modal... TODO: a
}

TwistedModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnEsc: PropTypes.bool,
  closeOnBlur: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  domRef: PropTypes.object,
  background: PropTypes.bool,
  size: PropTypes.oneOf([
    'tiny',
    'mini',
    'small',
    'medium',
    'large',
    'fullscreen',
    'auto',
  ]), //tiny
  card: PropTypes.bool,
  header: PropTypes.node,
  cardBody: PropTypes.node,
  mobileHeight: PropTypes.oneOf(['fullscreen', 'auto']),
  animation: PropTypes.oneOf(['fade', 'up', 'down', 'left', 'right']),
  mobileAnimation: PropTypes.oneOf(['fade', 'up', 'down', 'left', 'right']),
  contentClass: PropTypes.string,
  cardActions: PropTypes.node,
  showClose: PropTypes.bool,
  closeClass: PropTypes.string,
  fixedAction: PropTypes.node,
  noPadding: PropTypes.bool,
  beforeBox: PropTypes.node,
  boxStyle: PropTypes.object,
  modalWithTabs: PropTypes.bool,
}

export default withSizes(MAP_SIZES_TO_PROPS)(TwistedModal)
