import blog from './blog'
import motorcycles from './motorcycles'
import marketing from './marketing'
import partner from './partner'
import listing from './listMotorcycle'
import payment from './payments'
import users from './users'
import dashboard from './dashboard'
import conversations from './conversations'
import events from './motorcycleEvents'
import defaultRoute from './defaultRoute'
import RiderRequestDocs from './requestDocs'
import DoesNotExist from './doesNotExistRoute'
import MotorcycleBrands from './brands'

let routes = [
  ...marketing,
  ...users,
  ...blog,
  ...dashboard,
  ...listing,
  ...motorcycles,
  ...partner,
  ...payment,
  ...conversations,
  ...events,
  ...defaultRoute,
  ...RiderRequestDocs,
  ...MotorcycleBrands,
  ...DoesNotExist
]

export default routes
