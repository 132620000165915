import { lazyWithRetry } from 'components/lazyUtil.js'

const PartnerProfile = lazyWithRetry(() => import('../../partners/partnerProfile/partnerProfile.js'))

const routes = [
  {
    path: '/partners/:userID',
    component: PartnerProfile,
    private: false,
  },
]

export default routes
