import { apiProcess } from '../api/api.js'
import * as Cookies from 'js-cookie'
import { AFTER_LOGOUT_DEFAULT_LANDING } from '../../constants'
import { trackLogOut } from '../elements/tracking/loginTracking.js'
export let auth = {
  login(username, password) {
    return authenticate(username, password)
  },
  logout() {
    return unauthenticate(true)
  },
  isAuthenticated() {
    return isAuthenticated()
  },
  userRideTakenCount() {
    return rideTakenCount()
  },
  userAvatarID() {
    return avatarID()
  },
  userAvatarURL() {
    return avatarURL()
  },
  userID() {
    return userID()
  },
  userFullName() {
    return userFullName()
  },
  userEmail() {
    return userEmail()
  },
  userPhone() {
    return userPhoneNumber()
  },
  userIsEmailVerified() {
    return isEmailVerified()
  },
  userIsPhoneVerified() {
    return isPhoneVerified()
  },
  whoAmI() {
    return whoAmI()
  },
}

const authenticate = (username, password) => {
  let apiURL = 'users/sessions'
  let loginData = {
    email: username,
    password: password,
  }
  return apiProcess(apiURL, loginData, 'login', 'Login Form')
    .then(function(responseData) {
      //console.log(responseData)
      setUserCookies({ ...responseData, username })
      if (responseData.user.rides_taken_count > 0) {
        document.body.classList.remove('klaviyo-desktop-banner-added')
        document.body.classList.remove('klaviyo-mobile-banner-added')
      }
      return responseData
    })
    .catch(function(responseData) {
      throw responseData
    })
}

export const unauthenticate = (doTrackingReset) => {
  let apiURL = 'users/sessions'
  return apiProcess(
    apiURL,
    { refresh_token: userRefreshToken() },
    'logout',
    'Logged Out'
  )
    .then(function(responseData) {
      afterLogout(doTrackingReset)
    })
    .catch(function(responseData) {
      afterLogout(doTrackingReset)
    })
}

const isAuthenticated = () => {
  const authToken = Cookies.get('userToken') ? true : false
  return authToken
}

const rideTakenCount = () => {
  return Cookies.get('userRideTakenCount')
}

const avatarURL = () => {
  return Cookies.get('userAvatar')
}

const avatarID = () => {
  return Cookies.get('userAvatarID')
}

const userID = () => {
  return Cookies.get('userID')
}

const userFullName = () => {
  return Cookies.get('userFullName')
}

const userEmail = () => {
  return Cookies.get('userEmail')
}

const userPhoneNumber = () => {
  return Cookies.get('userPhoneNumber')
}

const userRefreshToken = () => {
  return Cookies.get('userRefreshToken')
    ? Cookies.get('userRefreshToken')
    : 'notoken'
}

const isPhoneVerified = () => Cookies.get('userIsPhoneVerified') === 'true'

const isEmailVerified = () => Cookies.get('userIsEmailVerified') === 'true'

export const afterLogout = (doTrackingReset) => {
  Cookies.remove('userToken')
  Cookies.remove('userRefreshToken')
  Cookies.remove('userAdmin')
  Cookies.remove('token_time')
  Cookies.remove('userAvatar')
  Cookies.remove('userAvatarID')
  Cookies.remove('userFullName')
  Cookies.remove('userFirstName')
  Cookies.remove('userBirthDate')
  Cookies.remove('userPhoneNumber')
  Cookies.remove('userLastName')
  Cookies.remove('userCity')
  Cookies.remove('userState')
  Cookies.remove('userID')
  Cookies.remove('userEmail')
  Cookies.remove('userIsPartner')
  Cookies.remove('userIsPhoneVerified')
  Cookies.remove('userIsEmailVerified')
  Cookies.remove('userRideTakenCount')
  Cookies.remove('userRegion')
  Cookies.remove('userIPAddress')
  trackLogOut(doTrackingReset)
  window.location.replace(AFTER_LOGOUT_DEFAULT_LANDING)
}

export const setUserCookies = (data) => {
  data.vouched_public_key && Cookies.set('vouchedPublicKey', data.vouched_public_key)
  data.vouched_webhook_url && Cookies.set('vouchedWebhookUrl', data.vouched_webhook_url)
  data.token && Cookies.set('userToken', data.token)
  data.refresh_token && Cookies.set('userRefreshToken', data.refresh_token)
  data.avatar_url && Cookies.set('userAvatar', data.avatar_url)
  data.avatar_cloudinary_public_id &&
    Cookies.set('userAvatarID', data.avatar_cloudinary_public_id)
  data.token && Cookies.set('token_time', Date.now() + 120000) //2 mins in milliseconds
  Cookies.set('userBirthDate', data.user?.birthdate)
  Cookies.set('userAdmin', data?.user?.is_admin || false)
  Cookies.set('userID', data.user.user_pretty_id)
  Cookies.set('userEmail', data.username || data.user.user_email)
  Cookies.set('userFullName', data.user.first_name + ' ' + data.user.last_name)
  Cookies.set('userFirstName', data.user.first_name)
  Cookies.set('userPhoneNumber', data.user.phone)
  Cookies.set('userCity', data.user.city)
  Cookies.set('userState', data.user.state)
  Cookies.set('userLastName', data.user.last_name)
  Cookies.set('userIsPartner', data.user.is_partner)
  Cookies.set('userIsPhoneVerified', data.user.phone_verified)
  Cookies.set('userIsEmailVerified', data.user.email_confirmed)
  Cookies.set('userPartnerAllowMessaging', data?.user?.partner_allow_messaging)
  Cookies.set('userRideTakenCount', data.user.rides_taken_count)
}

const whoAmI = () => {
  return apiProcess('whoami', false, 'logged-in-check', 'Login Checker')
    .then((responseData) => {
      setUserCookies(responseData)
      return responseData
    })
    .catch((error) => {
      if (!error.response || error.response.status !== 401) {
        console.error(error.message)
      }
    })
}
