export const userBasicInfoTransformer = (data) => {

    //console.log(data)
    let formattedBasicInfo = {
      first_name: data.user.first_name,
      last_name: data.user.last_name,
      birthdate: data.user.birthdate,
      birth_date_full: data.user.birthdate,
      email: data.user.email,
      gender: data.user.gender,
      address_line_1: data.user.location.address_street_1,
      address_line_2: data.user.location.address_street_2,
      address_city: data.user.location.city,
      address_region: data.user.location.region_abbreviation,
      address_postal_code: data.user.location.postal_code,
      timezone: data.user.timezone,
      address_country: data.user.location.country_abbreviation,
      rider_resume: data.user.rider_resume,
      phone: data.user.phone,
      rider_experiences: data.user.rider_experiences
    }


return formattedBasicInfo

}
