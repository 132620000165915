import chatSessionsListTransformer from '../transformers/chatSessionsListTransformer'

export const CHAT_SESSION_CALLS = {
  'create-chat-session': {
    use_mock: false,
    mock_endpoint: 'chat_sessions',
    api_type: 'POST',
    requires_auth: true,
  },
  'chat-sessions-list': {
    use_mock: false,
    mock_endpoint: 'chat_sessions',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return chatSessionsListTransformer(data)
    },
  },
}
