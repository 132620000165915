import React  from "react"
import { ErrorMessage } from "formik"
import { Help } from "react-bulma-components/lib/components/form"
export { default as Columns } from "react-bulma-components/lib/components/columns"
export { default as Container } from "react-bulma-components/lib/components/container"
export { default as Header } from "react-bulma-components/lib/components/heading"
export { default as Navbar } from "react-bulma-components/lib/components/navbar"
export { default as Breadcrumb } from "react-bulma-components/lib/components/breadcrumb"
export { default as Image } from "react-bulma-components/lib/components/image"
export { default as Menu } from "react-bulma-components/lib/components/menu"
export { default as Icon } from "react-bulma-components/lib/components/icon"
export { default as Form } from "react-bulma-components/lib/components/form"
export { default as Pagination } from "react-bulma-components/lib/components/pagination"
export { default as Box } from "react-bulma-components/lib/components/box"
export { default as Notification } from "react-bulma-components/lib/components/notification"
export { default as Dropdown } from "react-bulma-components/lib/components/dropdown"
export { default as Progress } from "react-bulma-components/lib/components/progress"
export { default as Table } from "react-bulma-components/lib/components/table"
export { default as Field } from "./formFields"
export { default as Modal } from "./modal"
export { default as Button } from "./button"
export { default as TryMeModal} from "./tryMeModal"

//Why this?
//Well...bulma is all CSS based. So if for some reason the react library is abandoned, we can easily
//recreate the components our selves.

export const InlineError = ({name}) => (
  <ErrorMessage component={Help} color="danger" name={name} />
)

